import React, {
  Suspense,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
import SocialContext from "src/context/SocialAuth";
import WalletContext from "src/context/WalletAuth";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
import walletGuard from "src/component/walletGuard";
import { ThemeProvider } from "@material-ui/core";
import { createTheme1 } from "src/theme";
import SettingsContext from "src/context/SettingsContext";
import { Toaster } from "react-hot-toast";
import firebase from "./firebase";

const history = createBrowserHistory();
// ReactGA.initialize("G-V68T3ZYK5G");
// ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  const themeSeeting = useContext(SettingsContext);
  const theme = createTheme1({
    theme: themeSeeting.settings.theme,
  });

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    window.onload = function () {
      document.addEventListener(
        "contextmenu",
        function (e) {
          e.preventDefault();
        },
        false
      );
      document.addEventListener(
        "keydown",
        function (e) {
          //document.onkeydown = function(e) {
          // "I" key
          if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
            disabledEvent(e);
          }
          // "J" key
          if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
            disabledEvent(e);
          }
          // "S" key + macOS
          if (
            e.keyCode == 83 &&
            (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
          ) {
            disabledEvent(e);
          }
          // "U" key
          if (e.ctrlKey && e.keyCode == 85) {
            disabledEvent(e);
          }
          // "F12" key
          if (e.keyCode == 123) {
            disabledEvent(e);
          }
        },
        false
      );

      function disabledEvent(e) {
        if (e.stopPropagation) {
          e.stopPropagation();
        } else if (window.event) {
          window.event.cancelBubble = true;
        }
        e.preventDefault();
        return false;
      }
    };
  }, []);

  const getToken = async () => {
    const messaging = firebase.messaging();
    let currentToken = "";
    try {
      currentToken = await messaging.getToken({
        vapidKey:
          "BA9RcSv7mc10uig7lDL2sSoKGQbT2SVkzHfAJxSQwNdq-N3J9lHCcaLVfDCuJh0H_sOhl5zvuL0pzQwucqVBK-4",
      });
      window.localStorage.setItem("pushToekn", currentToken);
    } catch (error) {
      console.log("An error occurred while retrieving token.", error);
    }
    return currentToken;
  };

  useEffect(() => {
    getToken();
  }, []);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SocialContext>
            <WalletContext>
              <Toaster
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                // theme="light"
                theme={theme.palette.type}
              />
              <Router history={history}>
                <RenderRoutes data={routes} />
              </Router>
            </WalletContext>
          </SocialContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Walletguard = route.wallltGuard ? walletGuard : Fragment;
          const Layout = route.layout || Fragment;

          return (
            <Route
              exact={route.exact}
              key={`route${i}`}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Walletguard>
                    <Layout>
                      {route.routes ? (
                        <RenderRoutes data={route.routes} />
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Walletguard>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
