import React, { useState, useEffect, useContext } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  withStyles,
  Typography,
  MenuItem,
  Menu,
  Grid,
  TextField,
  InputAdornment,
  FormControl,
  Button,
  ListItem,
  List,
  Popper,
  ClickAwayListener,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FaUserAlt, FaCommentMedical } from 'react-icons/fa';
import TagFriend from 'src/component/TagFriend';
import CreateFeedModal from 'src/component/CreateFeedModal';
import StoryPreview from 'src/views/pages/Dashboard/Story/StoryPreview';
import { SocialContext } from 'src/context/SocialAuth';
import { AiFillHeart } from 'react-icons/ai';
import { BsFillChatDotsFill, BsFillChatRightDotsFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { IoSearchOutline } from 'react-icons/io5';
import UseProfileNavBar from './RightDrawer';
import { IoMdAddCircle } from 'react-icons/io';
import Logo from 'src/component/Logo';
import { IoIosNotifications } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { getParticularObjectDataHanndler } from 'src/Apiconfigs/service';
import MightSkeleton from 'src/component/MightSkeleton';
import moment from 'moment';
import NodatafoundImage from 'src/component/NoDataFound';
import PostCommonModal from 'src/component/PostCommonModal';
import AddTadak from 'src/component/AddTadak';
import { AiFillGift } from 'react-icons/ai';
import { IoMdAdd } from 'react-icons/io';
import { MdOutlineDone } from 'react-icons/md';
import { FiArrowDown } from 'react-icons/fi';
import { RiDislikeFill, RiUserFollowLine } from 'react-icons/ri';
import { CiWallet } from 'react-icons/ci';
import CreatePoolModal from 'src/component/storysidebar/CretePoolModal';
import NotificationConfirmation from '../../component/NotificationConfirmation';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const StyledMenu = withStyles({
  paper: {
    minWidth: '220px !important',
    boxShadow: '0px 0px 11px rgba(0, 0, 0, 0.16)',
    background: 'rgba(32, 32, 32, 0.7)',
    border: '1px solid #3b3932',
    backdropFilter: 'blur(150px)',
    borderRadius: '15px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: '14px',
    fontWeight: '500',
    borderRadius: 0,
    minWidth: 'auto',
    color: 'rgba(255, 255, 255, 0.6)',
    padding: '0px 14px',
    letterSpacing: '1px',
    marginLeft: '15px',
    textDecoration: 'none',
    lineHeight: '54px',
    display: 'flex',
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
    '@media (max-width: 1280px)': {
      fontStyle: 'normal',
      letterSpacing: '1px',
      lineHeight: '7px',
      padding: '12px 0px !important',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      height: 'auto',
    },
    '&.activeMenu': {
      color: '#FDC763',
      position: 'relative',
      '&:before': {
        content: "' '",
        position: 'absolute',
        top: '58px',
        left: '-5px',
        width: '100%',
        height: '4px',
        borderRadius: '4px',
        background: '#FDC763',
        '@media (max-width: 1280px)': {
          top: '45px',
        },
        [theme.breakpoints.down('md')]: {
          top: '45px',
        },
      },
    },
    '&:hover': {
      color: '#FDC763',
    },
  },
  menuul: {
    display: 'flex',
    listStyle: 'none',
    padding: '0',
    alignItems: 'center',
    height: '30px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      '& .logoDesk': {
        display: 'none',
      },
    },
  },
  inerrMenu: {
    display: 'flex',
    listStyle: 'none',
    padding: '0',
    whiteSpace: 'nowrap',
    paddingLeft: '25px',
    // alignItems: "center",
    justifyContent: 'flex-start',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingLeft: '5px',
    },
    '& li': {
      '&.active': {
        background: 'red',
      },
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  desktopDrawer: {
    position: 'absolute',
    right: 80,
    top: 30,
    width: 450,
    // background: theme.palette.primary.main,
    height: 450,
    [theme.breakpoints.down('sm')]: {
      width: 300,
      right: 0,
    },
  },
  iconbutton: {
    // color: theme.palette.secondary.main,
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
    },
    '& div': {
      height: '5px',
      width: '5px',
      borderRadius: '50%',
      backgroundColor: '#6FCFB9',
      position: 'absolute',
      top: '7px',
      right: '8px',
    },
  },
  mainheader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& .leftBox': {
      width: '306px',
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        width: '200px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '150px',
      },
      '& img': {
        [theme.breakpoints.down('xs')]: {
          paddingLeft: '0 !important',
        },
      },
    },

    '& .MuiOutlinedInput-input': {
      paddingRight: '12px',
    },
  },
  searchBoxClass: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  notification: {
    position: 'relative',
    margin: '0px 10px',
    '& button': {
      backgroundColor: '#f2f2f2b8',
      '& svg': {
        color: '#fff',
        fontSize: '25px',
      },
    },
    '& .MuiIconButton-root': {
      fontSize: '15px !important',
    },
  },
  addStoryMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '5px 0px',

    '& h6': {
      color: '#fff',
      padding: '0px 60px 0px 50px',
    },
    '& figure': {
      margin: '0px',
      width: '20px',
      height: '20px',
      '& img': {
        width: '100%',
      },
    },
    '& p': {
      marginLeft: '10px',
    },
  },
  ProfileNotification: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  imageBox: {
    '& img': {
      width: '25px',
      position: 'relative',
      bottom: '15px',
      zIndex: '1',
      left: '23px',
      border: '1px solid #fff',
      borderRadius: '100px',
    },
  },
  notificationbox: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '& span': {
      color: '#FDC763',
    },
  },
  typo: {
    padding: ' 12px 0px 14px',
    '& p': {
      fontWeight: '600',
      paddingLeft: '15px',
    },

    '& h5': {
      fontSize: '10px',
      fontWeight: '500',
      position: 'absolute',
      cursor: 'pointer',
      top: '16px',
      right: '15px',
      color: '#56B3E8',
      textDecoration: 'underline',
    },
  },
  searchControl: {
    '& .MuiInputBase-root': {
      boxShadow: 'none !important',
      background: '#1E1D1D !important',
      border: '1px solid #1E1D1D',
      borderRadius: '50px !important',
      height: '39px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E1D1D !important',
    },
  },
  profile: {
    display: 'flex',
    alignItems: 'center',

    '& p': {
      fontWeight: '600',
    },
  },
  responsivesec: {
    '& .MuiPopover-paper': {
      outline: '0',
      position: 'absolute',
      minHeight: '16px',
      overflowX: 'hidden',
      overflowY: 'auto',
      top: '77px !important',
      // right: "11px !important",
    },
    '@media(min-width:980px)': {
      display: 'none',
      '& .MuiPopover-paper': {
        top: '0px !important',
      },
    },
  },
  responsivesec1: {
    '& .MuiPopover-paper': {
      top: '0px !important',
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color='default' {...rest}>
      <Toolbar>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData({ type }) {
  const {
    menuul,
    searchBoxClass,
    searchControl,
    searchText,
    ProfileNotification,
    mainheader,
    addStoryMenu,
    iconbutton,
    notification,
    inerrMenu,
    menuButton,
    notificationbox,
    typo,
    responsivesec,
    responsivesec1,
    profile,
  } = useStyles();
  const [feedmodal, setFeedModal] = React.useState(false);
  const [storyModal, setStoryModal] = useState(false);
  const social = useContext(SocialContext);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationpopup, setNotificationPopup] = React.useState(null);
  const [openStoryPreview, setStoryPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openPostModal, setOpenPostModal] = useState(false);
  const [openTadakModal, setOpenTadakModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [noOfPages, setNoOfPages] = useState(1);
  const [notificationList, setNotificationList] = useState([]);
  const [friendModal, setFriendModal] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [ponstModal, setPonstModal] = React.useState(false);
  const [poolModal, setPoolModal] = React.useState(false);
  const [notiModal, setNotiModal] = React.useState(false);
  const [openModalData, setOpenModalData] = React.useState({});
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;

  const getNotificationListAPIHandler = async () => {
    const dataSend = {
      page: 1,
      perPage: 10,
    };
    try {
      setNotificationList([]);
      const response = await getParticularObjectDataHanndler(
        type === 'crypto' ? 'walletnotifications' : 'notifications',
        dataSend,
        window.localStorage.getItem('token')
      );
      if (response?.status === 200) {
        setNotificationList(
          type === 'crypto'
            ? response?.data?.data?.history
            : response?.data?.data
        );
        setIsLoading(false);
        setNoOfPages(
          type === 'crypto'
            ? response.data.data.totalPages
            : response.data.meta.pages
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Boolean(notificationpopup)) {
      getNotificationListAPIHandler();
    }
  }, [notificationpopup]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
  }, []);

  //popper for add poll
  const [anchorEl11, setAnchorEl11] = React.useState(null);

  const handleClick11 = (event) => {
    setAnchorEl11(anchorEl11 ? null : event.currentTarget);
  };

  const open11 = Boolean(anchorEl11);
  const id = open11 ? 'simple-popper' : undefined;
  function notificationTitleGenerator(data) {
    const splitedText = data?.title?.split(')')[1];
    return `${splitedText}`;
  }
  const handleClickAway1 = () => {
    setAnchorEl11(false);
  };
  const searchBox = (
    <Box className={searchBoxClass} onClick={() => history.push('/search')}>
      <FormControl fullWidth className={`${searchControl} placeholderColor`}>
        <TextField
          variant='outlined'
          placeholder='Search......'
          type='search'
          value={social.search}
          autoComplete='none'
          autoFocus={false}
          onChange={(e) => social.setSearch(e.target.value)}
          className={searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <IconButton edge='start'>
                  <IoSearchOutline />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Box>
  );
  const ProfileAndNotification = (
    <Box className={ProfileNotification}>
      {type !== 'crypto' && (
        <IconButton
          style={{ marginRight: '10px' }}
          onClick={() => history.push('/chat-history')}
          className='iconButtonbackground'
        >
          <BsFillChatDotsFill style={{ color: '#fff', fontSize: '30px' }} />
        </IconButton>
      )}
      {/* <Hidden xsDown> */}
      {type !== 'crypto' && social?.userData?.user_type !== 'page' && (
        <IconButton
          onClick={() => {
            history.push(
              social?.userDataWallet?.wallet_pin === null
                ? '/create-pin'
                : social?.userDataWallet?.mobile_status === '0' ||
                  social?.userDataWallet?.email_status === '0'
                ? '/verify-kyc'
                : '/crypto-login'
            );
          }}
          className='iconButtonbackground'
        >
          <img src='images/social_icon.png' width='28px' />
        </IconButton>
      )}
      {/* </Hidden> */}
      {type === 'crypto' && (
        <IconButton
          onClick={() => history.push('/home')}
          className='iconButtonbackground'
        >
          <img src='images/loginlogopin.png' width='28px' />
        </IconButton>
      )}
      {/* {type !== "crypto" && (
        <>
          <Box
            className={`${responsivesec} responsiveSec copperClss`}
            style={{ margin: "0px 10px" }}
          >
            <IconButton
              onClick={handleClick11}
              className="iconButtonbackground"
            >
              <IoMdAdd
                style={{
                  color: "rgb(255, 255, 255)",
                  fontSize: "44px",
                }}
              />
            </IconButton>
          </Box>
          <Popper
            open={open11}
            anchorEl={anchorEl11}
            className="toolTipForMenu"
          >
            <ClickAwayListener onClickAway={handleClickAway1}>
              <List
                id="customized-menu111"
                className="listMenuForPoll"
                style={{ top: "0px" }}
              >
                <ListItem
                  onClick={() => setFeedModal(true)}
                  style={{ fontSize: "14px" }}
                >
                  Post
                </ListItem>
                <ListItem
                  onClick={() => setPoolModal(true)}
                  style={{ fontSize: "14px" }}
                >
                  Poll
                </ListItem>
              </List>
            </ClickAwayListener>
          </Popper>
        </>
      )} */}
      <Hidden xsDown>
        <Box className={notification}>
          <IconButton
            className='iconButtonbackground'
            onClick={(event) => setNotificationPopup(event.currentTarget)}
          >
            {/* <Box className="circleBox"></Box> */}
            <IoIosNotifications />
          </IconButton>
        </Box>
      </Hidden>
      <StyledMenu
        id='customized-menu'
        anchorEl={notificationpopup}
        keepMounted
        open={Boolean(notificationpopup)}
        onClose={() => setNotificationPopup(null)}
      >
        <Box className={typo}>
          <Typography variant='body2' style={{ color: '#fff' }}>
            Notifications
          </Typography>
          {notificationList && notificationList?.length >= 6 && (
            <Typography
              variant='h5'
              onClick={() => {
                history.push({
                  pathname:
                    type === 'crypto'
                      ? '/notification-lists'
                      : '/notification-list',
                  state: {
                    notificationList: notificationList,
                    isLoading: isLoading,
                    page: 1,
                    noOfPages: noOfPages,
                  },
                });
              }}
            >
              See All
            </Typography>
          )}
        </Box>

        <Box style={{ maxHeight: '350px', overflow: 'scroll' }}>
          {isLoading ? (
            [1, 2, 3, 4, 5, 6].map((data) => {
              return <MightSkeleton type='reportList' key={data} />;
            })
          ) : (
            <>
              {notificationList &&
                notificationList?.slice(0, 6).map((data, i) => {
                  return (
                    <StyledMenuItem>
                      <Box
                        className={notificationbox}
                        onClick={() => {
                          if (type !== 'crypto') {
                            if (
                              data?.type == '9' ||
                              data?.type == '6' ||
                              data?.type == '1' ||
                              data?.type == '11'
                            ) {
                              history.push({
                                pathname: '/view-othersprofile',
                                search: data?.from_id?.toString(),
                              });
                            }
                            if (
                              data?.type == '2' ||
                              data?.type == '1' ||
                              data?.type == '3' ||
                              data?.type == '4'
                            ) {
                              history.push({
                                pathname: '/post-detail',
                                search: data?.related_id?.toString(),
                                state: 'notification_sec',
                              });
                            }
                          } else {
                            if (
                              data?.notification_type == '1' ||
                              data?.notification_type == '2'
                            ) {
                            }
                            if (
                              data?.notification_type == '4' ||
                              data?.notification_type == '5' ||
                              data?.notification_type == '6' ||
                              data?.notification_type == '9'
                            ) {
                            }
                            if (data?.notification_type == '7') {
                            }
                            if (data?.notification_type == '8') {
                              setNotiModal(true);
                              setOpenModalData({
                                message: data?.announcement_message,
                                title: data?.subject,
                              });
                            }
                            if (data?.notification_type == '10') {
                            }
                            if (
                              data?.notification_type == '0' ||
                              data?.notification_type == '3' ||
                              data?.notification_type == '11'
                            ) {
                              setNotiModal(true);
                              setOpenModalData({
                                message: data?.message,
                                title: data?.tx_type,
                              });
                            }
                          }
                        }}
                      >
                        <Box display='flex' alignItems='center'>
                          <Box minWidth='50px'>
                            <Box className='circle'>
                              {data?.type === '18' || data?.type === '2' ? (
                                <IconButton style={{ color: '#C7762B' }}>
                                  <BsFillChatRightDotsFill />
                                </IconButton>
                              ) : (
                                ''
                              )}
                              {data?.type === '4' ? (
                                <IconButton style={{ color: '#C7762B' }}>
                                  <FaCommentMedical />
                                </IconButton>
                              ) : (
                                ''
                              )}
                              {data?.type === '5' ||
                              data?.type === '9' ||
                              data?.type === '6' ? (
                                <IconButton style={{ color: '#C7762B' }}>
                                  <FaUserAlt />
                                </IconButton>
                              ) : (
                                ''
                              )}
                              {data?.type === '1' ||
                              data?.type === '12' ||
                              data?.type === '16' ? (
                                <IconButton style={{ color: '#C7762B' }}>
                                  <AiFillHeart />
                                </IconButton>
                              ) : (
                                ''
                              )}
                              {data?.type === '11' || data?.type === '17' ? (
                                <IconButton style={{ color: '#C7762B' }}>
                                  <RiDislikeFill />
                                </IconButton>
                              ) : (
                                ''
                              )}
                              {data?.type === '15' ? (
                                <IconButton style={{ color: '#C7762B' }}>
                                  <AiFillGift />
                                </IconButton>
                              ) : (
                                ''
                              )}

                              {data?.notification_type == '0' ||
                              data?.notification_type == '1' ||
                              data?.notification_type == '4' ||
                              data?.notification_type == '6' ||
                              data?.notification_type == '7' ||
                              data?.notification_type == '10' ||
                              data?.notification_type == '13' ? (
                                <IconButton style={{ color: '#C7762B' }}>
                                  <MdOutlineDone />
                                </IconButton>
                              ) : (
                                ''
                              )}
                              {data?.notification_type == '2' ||
                              data?.notification_type == '5' ||
                              data?.notification_type == '9' ? (
                                <IconButton style={{ color: '#C7762B' }}>
                                  <FaUserAlt />
                                </IconButton>
                              ) : (
                                ''
                              )}
                              {data?.notification_type == '3' ||
                              data?.notification_type == '11' ||
                              data?.notification_type == '12' ? (
                                <IconButton style={{ color: '#C7762B' }}>
                                  <FiArrowDown />
                                </IconButton>
                              ) : (
                                ''
                              )}
                              {data?.notification_type == '8' ? (
                                <IconButton style={{ color: '#C7762B' }}>
                                  <IoIosNotifications />
                                </IconButton>
                              ) : (
                                ''
                              )}
                            </Box>
                          </Box>
                          <Box ml={2} mt={1}>
                            <Typography
                              variant='body2'
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'break-spaces',
                                fontSize: '12px',
                                color: '#ffff',
                              }}
                            >
                              {type !== 'crypto' ? (
                                <>
                                  <span>@{data?.from_user_data?.username}</span>
                                  {data && notificationTitleGenerator(data)}
                                </>
                              ) : (
                                <>{data?.message}</>
                              )}
                            </Typography>

                            <Typography
                              variant='body1'
                              style={{ color: '#6C6C6C' }}
                            >
                              {moment(data?.created_at).startOf('').fromNow()}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </StyledMenuItem>
                  );
                })}
              {notificationList && notificationList.length === 0 && (
                <NodatafoundImage />
              )}
              {openPostModal && (
                <PostCommonModal
                  modalData={modalData}
                  type='commentModal'
                  arrayData={notificationList}
                  setOpenModal={(item) => setOpenPostModal(item)}
                  openModal={openPostModal}
                />
              )}
            </>
          )}
        </Box>
      </StyledMenu>
      <Box mx={1} className={type === 'crypto' && profile}>
        {type === 'crypto' && (
          <Box mr={1} style={{ display: 'none' }}>
            <Typography variant='body1' style={{ whiteSpace: 'nowrap' }}>
              {social?.userData.name}
            </Typography>
            <Typography
              variant='body1'
              style={{
                fontSize: '10px',
                fontWeight: '100',
                letterSpacing: '0.5px',
              }}
            >
              {social?.userData.username}
            </Typography>
          </Box>
        )}
        <UseProfileNavBar type={type} />
      </Box>
    </Box>
  );
  return (
    <>
      <Box className={mainheader}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item xs={5} md={3} lg={3}>
            <Box className='leftBox'>
              <Logo width='125' type={type} />
            </Box>
          </Grid>
          {type !== 'crypto' && (
            <Hidden smDown>
              <Grid item sm={3} md={3} lg={3} align='left'>
                {searchBox}
              </Grid>
              <Grid item xs={4} md={3} lg={3} align='right'>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    variant='contained'
                    color='secondary'
                    style={{ borderRadius: '50px' }}
                    onClick={() => {
                      setFeedModal(true);
                    }}
                  >
                    <IoMdAdd
                      style={{
                        color: '#fff',
                        fontSize: '23px',
                        marginRight: '5px',
                      }}
                    />
                    Create new post
                  </Button>
                  {/* {menuText} */}
                </Box>
              </Grid>
            </Hidden>
          )}
          <Hidden mdUp>
            <Grid item xs={6} align='right'>
              {ProfileAndNotification}
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item xs={4} md={3} lg={3} align='right'>
              {ProfileAndNotification}
            </Grid>
          </Hidden>
        </Grid>

        <TagFriend
          handleClickCloseFriendModal={() => setFriendModal(false)}
          friendModal={friendModal}
        />
        <AddTadak
          openAddStory={openTadakModal}
          setOpenAddStory={(item) => setOpenTadakModal(item)}
        />
        <StoryPreview
          openStoryPreview={openStoryPreview}
          closeStoryPreview={() => setStoryPreview(false)}
          storyModal={storyModal}
          setStoryModal={(item) => setStoryModal(item)}
        />
      </Box>
      <CreateFeedModal
        setFeedModal={setFeedModal}
        feedmodal={feedmodal}
        type='new'
      />
      <NotificationConfirmation
        open={notiModal}
        handleClose={() => setNotiModal(false)}
        title={openModalData?.title}
        desc={openModalData?.message}
      />

      <CreatePoolModal setFeedModal={setPoolModal} feedmodal={poolModal} />
    </>
  );
}
