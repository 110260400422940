import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Button, makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import Apiconfigs from "src/Apiconfigs/config";
import { AiFillLike, AiFillDislike, AiFillDelete } from "react-icons/ai";
import { IoGiftSharp, IoChatbubble } from "react-icons/io5";
import CommentListSkeleton from "src/Skeletons/CommentListSkeleton";
import CommentBox from "src/component/CommentBox";
import toast from "react-hot-toast";
import { getPerticularIdByPath, putAPIHandler } from "src/Apiconfigs/service";
import CommentSection from "./CommentSection";
import { SocialContext } from "src/context/SocialAuth";
import DeleteConfirmation from "src/component/DeleteConfirmation";
import { HiChatBubbleBottomCenterText } from "react-icons/hi2";
const useStyles = makeStyles((theme) => ({
  accordianDetails: {
    display: "block",
    padding: "15px 15px 0px",
    maxHeight: "450px",
    overflow: "scroll",
    "@media(max-width:960px)": {
      maxHeight: "450px",
    },
  },
  islikedClx: {
    color: "#FDC763 !important",
  },

  searchaddress1: {
    paddingTop: "16px",
    height: "50px",
  },
  accordianDetails1: {
    paddingTop: "10px",
    display: "block",
    maxHeight: "250px",
    overflow: "scroll",
    marginBottom: "0px",
    minHeight: "calc(100vh - 272px)",
  },
  modalComment: {
    "& .commentBox": {
      borderTop: "1px solid #fdc7630f",
      // borderBottom: "0.5px solid #E1E1E1",
      marginTop: "16px",
      padding: "5px 0",
      [theme.breakpoints.down("xs")]: {
        padding: "0px 0",
        marginTop: "10px",
      },
      "& .buttonBox": {
        color: "rgba(255,255,255,0.57)",
        border: "none",
        // marginTop: "12px",
        minWidth: "40px",
        [theme.breakpoints.down("sm")]: {
          minWidth: "40px",
        },
        [theme.breakpoints.down("xs")]: {
          minWidth: "40px",
        },
        "&:hover": {
          background: "transparent",
          border: "none",
          color: "#FDC763",
          "& svg": {
            color: "#FDC763",
          },
          "& p": {
            color: "#FDC763",
          },
        },
      },
      "& button": {
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px !important",
          height: "34px !important",
        },
        "& p": {
          color: "rgba(255,255,255,0.57)",
        },
        "& svg": {
          fontSize: "20px",
          marginRight: "5px",
          color: "rgba(255,255,255,0.57)",
          [theme.breakpoints.down("xs")]: {
            fontSize: "15px",
          },
        },
      },
    },
  },
}));

export default function PostCardAccordian({
  data,

  // callBack,
  type,
  calssType,
  typeData,
  postTipParticular,
  // callBackFun,
  arrayData,
  setImagePostList,
  index,
  setOpenModalPar,
}) {
  const social = useContext(SocialContext);
  const classes = useStyles();
  const [accordionValue1, setAccordionValue1] = useState(false);
  const [likeLoading, setLikeLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commentListData, setCommentListData] = useState(
    data?.comments ? data?.comments : []
  );
  const [commentLoader, setCommentLoader] = useState(true);
  const [totalPagesdata, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [comment, setComment] = useState("");
  const [commentModal, setCommentModal] = useState(false);
  const [gif, setGif] = useState("");
  const [likeMsg, setLikeMsg] = useState("");
  const [disLikeMsg, setDisLikeMsg] = useState("");
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [postId, setPostId] = useState("");
  const [likeCountData, setLikeCount] = useState({});
  const [commentCnt, setCommentCnt] = useState({});

  const handleLikePost = async (id, value) => {
    setLikeLoading(true);
    value == "1" && setLikeMsg("Liked");
    value == "0" && setDisLikeMsg("Disliked");
    setTimeout(() => {
      setLikeMsg("");
      setDisLikeMsg("");
    }, 2000);
    try {
      const res = await axios({
        method: "PUT",
        url: Apiconfigs.likePost,
        headers: {
          authorization: localStorage.getItem("token"),
        },
        data: {
          action: value,
          post_id: id,
        },
      });
      if (res?.data) {
        setLikeCount(res.data);
        social.getUserClaimRewardsPoint();
        setLikeLoading(false);
      } else {
        toast.error(res.data.message);
        setLikeLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLikeLoading(false);
    }
  };
  const commentHandler = async (id, commentType) => {
    setGif("");

    if (comment !== "") {
      setIsLoading(true);
      try {
        const res = await axios({
          method: "PUT",
          url: Apiconfigs.comment,
          headers: {
            authorization: localStorage.getItem("token"),
          },
          data: {
            comment: comment,
            post_id: id,
            type: commentType,
            mentions: [
              {
                user_id: "23455",
                user_type: "user",
              },
            ],
          },
        });
        if (res.data) {
          setCommentCnt(res?.data);
          setIsLoading(false);
          toast.success(res.data.message);
          // setCommentModal(true);
          getCommentsList(id, 1, "newComment");

          setPage(1);
          social.getUserClaimRewardsPoint();
          setComment("");
        } else {
          toast.error(res.data.message);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error(error.response.data.message);
        setComment("");
      }
    }
  };

  const getCommentsList = async (id, page, type) => {
    try {
      const paramData = {
        page: page,
      };
      const response = await getPerticularIdByPath("comments", id, paramData);
      if (response.data.data?.length !== 0) {
        if (type === "newComment") {
          setCommentListData(response.data.data);
        } else {
          const resultedArray = Object.values(
            [...commentListData, ...response.data.data].reduce(
              (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
              {}
            )
          );
          setCommentListData(resultedArray.reverse());
        }

        setTotalPages(response.data.meta.pages);
        setCommentLoader(false);
      } else {
        setCommentListData([]);
        setCommentLoader(false);
      }
    } catch (err) {
      console.log(err);
      setCommentLoader(false);
    }
  };
  const seeMoreButtonData = (
    <Box mt={1}>
      {totalPagesdata > page && (
        <Button
          color="primary"
          size="large"
          onClick={() => {
            getCommentsList(data?.id, page + 1);
            setPage(page + 1);
          }}
          disabled={commentLoader}
        >
          See more{commentLoader && <ButtonCircularProgress />}
        </Button>
      )}
      {totalPagesdata === page && (
        <Button
          color="primary"
          size="large"
          onClick={() => {
            setCommentListData(commentListData?.slice(0, 10));
            setPage(1);
          }}
          disabled={commentLoader}
        >
          {commentListData?.length !== 0 && commentListData?.length >= 10 && (
            <>Show less {commentLoader && <ButtonCircularProgress />}</>
          )}
        </Button>
      )}
    </Box>
  );

  const deleteHandler = async (id) => {
    const dataSend = {
      post_id: postId,
    };
    try {
      setIsLoading(true);
      const response = await putAPIHandler("deletePost", dataSend);
      if (response.status === 200) {
        toast.success(response.data.message);

        if (type === "other_user" || type === "Pool" || type === "hashtag") {
          // callBackFun();
          // callBack();
        } else {
          // social.getHomePagePostList(1, social?.postListData?.length, []);
        }
        if (setOpenModalPar) {
          setOpenModalPar(false);
        }
        setIsLoading(false);
        setDeleteModal(false);
        // console.log("arrayData", arrayData);
        let newFormValues = [...arrayData];
        newFormValues.splice(index, 1);
        // console.log("newFormValues", newFormValues);
        setImagePostList(newFormValues);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (calssType === "modal") {
      setCommentModal(true);
      getCommentsList(data?.id, 1);
    }
  }, [calssType]);
  useEffect(() => {
    const checkComment = comment.includes("giphy.com");
    if (checkComment) {
      commentHandler(data?.id, "image");
    }
  }, [comment]);

  return (
    <>
      <Box
        square
        onChange={() => {
          data?.total_comments !== 0 && setAccordionValue1(!accordionValue1);
        }}
        expanded={accordionValue1}
      >
        <Box className={classes.modalComment}>
          <Box className="commentBox">
            <Grid container style={{ justifyContent: "center" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="69%"
              >
                <Button
                  color="primary"
                  size="large"
                  disabled={
                    likeLoading || likeCountData?.is_liked !== undefined
                      ? likeCountData?.is_liked === 1
                      : data?.is_liked === 1
                  }
                  onClick={() => handleLikePost(data?.id, 1)}
                >
                  <AiFillLike
                    className={`${
                      likeCountData?.is_liked !== undefined
                        ? likeCountData?.is_liked === 1 && classes.islikedClx
                        : data?.is_liked === 1 && classes.islikedClx
                    } buttonBox`}
                  />{" "}
                  <Typography variant="body2">
                    {likeMsg
                      ? likeMsg
                      : likeCountData?.total_like !== undefined
                      ? likeCountData?.total_like
                      : data?.total_likes}
                  </Typography>
                </Button>
                <Button
                  color="primary"
                  size="large"
                  disabled={
                    likeLoading || likeCountData?.is_disliked !== undefined
                      ? likeCountData?.is_disliked === 1
                      : data?.is_disliked === 1
                  }
                  onClick={() => handleLikePost(data?.id, 0)}
                >
                  <AiFillDislike
                    className={`${
                      likeCountData?.is_disliked !== undefined
                        ? likeCountData?.is_disliked === 1 && classes.islikedClx
                        : data?.is_disliked === 1 && classes.islikedClx
                    } buttonBox`}
                  />{" "}
                  <Typography variant="body2">
                    {disLikeMsg
                      ? disLikeMsg
                      : likeCountData?.total_dislike !== undefined
                      ? likeCountData?.total_dislike
                      : data?.total_dislikes}
                  </Typography>
                </Button>
                <Box aria-controls="panel1d-content" id="panel1d-header">
                  <Button
                    color="primary"
                    size="large"
                    className="buttonBox"
                    onClick={() => {
                      if (calssType !== "modal") {
                        // if (
                        //   data?.total_comments !== null &&
                        //   data?.total_comments !== 0
                        // ) {
                        setCommentModal(!commentModal);
                        if (!commentModal) {
                          getCommentsList(data?.id, 1);
                        }
                        // }
                      }
                    }}
                  >
                    <HiChatBubbleBottomCenterText />
                    <Typography variant="body2">
                      {commentCnt?.total_comments !== undefined
                        ? commentCnt?.total_comments
                        : data?.total_comments}
                    </Typography>
                  </Button>
                </Box>
                {social?.userData?.user_id === data?.user_data?.user_id ? (
                  <Button
                    color="primary"
                    size="large"
                    className="buttonBox"
                    style={{ minWidth: "70px" }}
                    onClick={() => {
                      setDeleteModal(true);
                      setPostId(data?.id);
                    }}
                  >
                    <AiFillDelete />
                  </Button>
                ) : (
                  <>
                    {!data?.user_data?.is_page && (
                      <Button
                        color="primary"
                        size="large"
                        className="buttonBox"
                        style={{ minWidth: "70px" }}
                        disabled={
                          social?.userData?.user_id === data?.user_data?.user_id
                        }
                        onClick={() => postTipParticular(data?.id)}
                      >
                        <IoGiftSharp />
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Box>
        </Box>

        {commentModal && (
          <>
            {commentListData && commentListData.length !== 0 && (
              <Box
                className={
                  calssType !== "modal"
                    ? classes.accordianDetails
                    : classes.accordianDetails1
                }
              >
                {commentLoader ? (
                  <>
                    {[1, 2].map((item) => {
                      return <CommentListSkeleton />;
                    })}
                  </>
                ) : (
                  <>
                    {commentListData &&
                      commentListData?.map((data, i) => {
                        return <CommentBox data={data} index={i} key={i} />;
                      })}
                  </>
                )}
                {seeMoreButtonData}
              </Box>
            )}

            <Box
              className={
                calssType !== "modal" ? "searchaddress" : classes.searchaddress1
              }
            >
              <CommentSection
                data={data}
                social={social}
                isLoading={isLoading}
                setComment={(item) => setComment(item)}
                comment={comment}
                commentHandler={(item, commentType) =>
                  commentHandler(item, commentType)
                }
                setGif={(item) => setGif(item)}
                gif={gif}
                calssType={calssType}
              />
            </Box>
          </>
        )}
      </Box>

      {openDeleteModal && (
        <DeleteConfirmation
          open={openDeleteModal}
          setOpen={(item) => setDeleteModal(item)}
          deleteHandler={deleteHandler}
          type="post"
          isLoading={isLoading}
        />
      )}
    </>
  );
}
