import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";

export default function Player({ video, id, poster, currentPostId, index }) {
  const videoEl = useRef(null);

  useEffect(() => {
    if (currentPostId === index) {
      videoEl.current.play();
    } else {
      videoEl.current.pause();
    }
  }, [currentPostId]);
  return (
    <video
      ref={videoEl}
      controls={true}
      loop={false}
      playsinline="true"
      style={{
        width: "100%",
        height: "400px",
        cursor: "pointer",
      }}
      className="postImg11"
      // poster={poster}
    >
      <source src={video} type="video/ogg" />
    </video>
  );
}
