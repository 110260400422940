/* eslint-disable no-use-before-define */
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Divider,
  Button,
  Avatar,
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import {
  IoTimerSharp,
  IoSearchOutline,
  IoNewspaperOutline,
} from "react-icons/io5";
import Logo from "src/component/Logo";
import { FaUsers, FaUserPlus } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import NavItem from "./NavItem";
import { SocialContext } from "src/context/SocialAuth";
import { IMAGE_PATH } from "src/constants";
import { GoGraph } from "react-icons/go";
import { SiMarketo } from "react-icons/si";
import { FiUserPlus, FiSettings } from "react-icons/fi";

import {
  AiOutlineGold,
  AiOutlineSetting,
  AiOutlineStock,
} from "react-icons/ai";
import { TfiWallet } from "react-icons/tfi";
import { MdHistory, MdOutlineLiveHelp } from "react-icons/md";
import { BiHomeAlt, BiCoinStack, BiRupee } from "react-icons/bi";
import {
  convertINRDecimals,
  makeFriendly,
  numberCompactFormat,
  setCryptoDecimals,
} from "src/utils";
import React, { useEffect, useState, useContext, useMemo } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi";
import PropTypes from "prop-types";
import { WalletContext } from "src/context/WalletAuth";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { TbUsers } from "react-icons/tb";
import { RiTimeLine } from "react-icons/ri";
import { AiOutlineHome, AiOutlinePieChart } from "react-icons/ai";
import ProfileBatch from "src/component/ProfileBatch";

const sections = [
  {
    items: [
      {
        title: "Home",
        icon: AiOutlineHome,
        href: "/home",
        comingsoon: false,
      },
      // {
      //   title: "Chat",
      //   icon: BsChatLeftDots,
      //   href: "/chat-history",
      //   comingsoon: false,
      // },

      // {
      //   title: "Tadak",
      //   icon: BsLightningFill,
      //   href: "/tadak",
      //   comingsoon: true,
      // },
      {
        title: "Earning",
        icon: GiReceiveMoney,
        href: "/earning",
        comingsoon: false,
      },
      {
        title: "Followers ",
        icon: TbUsers,
        href: "/followers",
        comingsoon: false,
      },
      {
        title: "Following",
        icon: FiUserPlus,
        href: "/following",
        comingsoon: false,
      },
      {
        title: "My Activities",
        icon: RiTimeLine,
        href: "/my-activities",
        comingsoon: false,
      },
      {
        title: "Profile Setting",
        icon: FiSettings,
        href: "/verify-myprofile",
        comingsoon: false,
      },
    ],
  },
];
const pageSections = [
  {
    items: [
      {
        title: "Home",
        icon: AiOutlineHome,
        href: "/home",
        comingsoon: false,
      },

      {
        title: "Followers ",
        icon: TbUsers,
        href: "/followers",
        comingsoon: false,
      },
      {
        title: "Following",
        icon: FiUserPlus,
        href: "/following",
        comingsoon: false,
      },
      {
        title: "My Activities",
        icon: RiTimeLine,
        href: "/my-activities",
        comingsoon: false,
      },
    ],
  },
];
const taralsections = [
  {
    items: [
      {
        title: "Home",
        icon: BiHomeAlt,
        href: "/wallet-home",
        comingsoon: false,
      },
      {
        title: "Wallet",
        icon: BiCoinStack,
        href: "/fiat-wallet",
        comingsoon: false,
      },

      {
        title: "Insta Buy/Sell",
        icon: AiOutlineStock,
        href: "/insta-buy-sell",
        comingsoon: false,
      },
      {
        title: "Market",
        icon: SiMarketo,
        href: "/market",
        comingsoon: false,
      },

      // {
      //   title: "Fiat ",
      //   icon: BiRupee,
      //   href: "/fiat-wallet",
      //   comingsoon: false,
      // },

      {
        title: "Taral Gold",
        icon: AiOutlineGold,
        href: "/taral-dashboard",
        comingsoon: false,
      },
      {
        title: "Stake",
        icon: AiOutlinePieChart,
        href: "/stake",
        comingsoon: false,
      },
      // {
      //   title: "Fund Raise",
      //   icon: IoTimerSharp,
      //   href: "/ico",
      //   comingsoon: false,
      // },
      {
        title: "History",
        icon: MdHistory,
        href: "/crypto-history",
        comingsoon: false,
      },
      {
        title: "Statement",
        icon: IoNewspaperOutline,
        href: "/statement",
        comingsoon: false,
      },
      {
        title: "Help & Support",
        icon: MdOutlineLiveHelp,
        href: "/help-support",
        comingsoon: false,
      },

      {
        title: "Setting",
        icon: AiOutlineSetting,
        href: "/taral-setting",
        comingsoon: false,
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        comingsoon={item.comingsoon}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        comingsoon={item.comingsoon}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}

const useStyles = makeStyles((theme) => ({
  bottomImageBoxBox: {
    zIndex: "1",
    position: "relative",
    backgroundSize: "cover",
    // padding: "50px 0",
    width: "100%",
    borderRadius: "15px",
    backgroundColor: "#000",
    backgroundImage: "url(./images/wallet_bottom.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    cursor: "pointer",
    bottom: "5px",
    "@media (max-width:599px)": {
      backgroundImage: "none",
      backgroundColor: "#c7762b",
    },
    "& .bottomTextBox": {
      padding: "30px 16px 30px",
      position: "relative",
      "& h5": {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        position: "relative",
      },
      "@media (max-width:599px)": {
        padding: "9px 16px 9px",
      },
      "&::before": {
        content: "' '",
        width: "100%",
        height: "100%",
        background: "rgb(7 0 0 / 44%)",
        position: "absolute",
        top: "0",
        left: "0",
        opacity: "1",
        borderRadius: "15px",
      },
    },
  },
  profilemainBox: {
    padding: "0px 0 10px",
    "& .imageBox": {
      width: "95px",
      height: "95px",
      border: "2px solid #fdc763",
      "@media (max-width:599px)": {
        width: "65px",
        height: "65px",
      },
    },
  },
  mobileDrawer: {
    width: "320px",
    maxWidth: "100%",
    position: "fixed",
    height: "100dvh",
    zIndex: "1000",
    background: "#151515 !important",
    marginLeft: "0",
    overflowY: "scroll",
    left: "0",
    right: "0",
    // padding: "10px",
    "& .drawerBoxMob": { height: "100dvh" },
    "& .closeButtonNav": {
      "& .svg": {
        fontSize: "none",
        width: "25px",
        height: "25px",
      },
    },
  },
  desktopDrawer: {
    top: "0px",
    left: "0px",
    width: "240px",
    height: "calc(100dvh - 112px)",
    padding: "10px",
    position: "sticky",
    background: "#090909 !important",
    borderRight: "0",
    border: "none !important",
    "@media (max-width:1279px)": {
      top: "0px",
    },
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#6FCFB9",
    },
    "& svg": {
      color: "#6FCFB9",
      fontSize: "20px",
    },
  },
  cryptwallet: {
    background: "rgba(255, 255, 255, 0.05)",
    padding: "10px",
    marginTop: "13px",
    "& h5": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
    },
  },
  searchControl: {
    "& .MuiInputBase-root": {
      boxShadow: "none !important",
      background: "#1E1D1D !important",
      border: "1px solid #1E1D1D",
      borderRadius: "50px !important",
      height: "39px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1E1D1D !important",
    },
  },
  searchBoxClass: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
    },
  },
  root: {
    position: "relative",
    zIndex: 1,
  },
}));

const NavBar = ({ onMobileClose, openMobile, type }) => {
  const classes = useStyles();
  const social = useContext(SocialContext);
  const userData = social?.userData;
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const walletAuth = useContext(WalletContext);
  // const checkFun = type === "crypto" ? taralsections : sections;
  const checkFun = useMemo(() => {
    return type === "crypto"
      ? taralsections
      : userData?.user_type === "page"
      ? pageSections
      : sections;
  }, [type, userData?.user_type]);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <>
      <Box style={{ position: "relative" }} className="mobileSideMenuItem">
        <Box>
          <Box mt={1} className={classes.profilemainBox} align="center">
            <Avatar
              src={userData && `${IMAGE_PATH}` + userData?.profile_url}
              alt={userData?.name ? userData?.name : userData?.user_name}
              className="imageBox"
            ></Avatar>
            <Box mt={2}>
              <Typography
                variant="h5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {userData?.name}
                <ProfileBatch data={userData} />
              </Typography>
              <Typography variant="body2">{userData?.username}</Typography>
            </Box>
            <Box align="center">
              {type === "crypto" ? (
                <Box className={classes.cryptwallet} align="center">
                  <Typography variant="body2">Wallet Balance</Typography>
                  <Typography variant="h5">
                    {walletAuth?.hide
                      ? convertINRDecimals(walletAuth?.balanceINR)
                      : "******"}
                    &nbsp;
                    <IconButton
                      onClick={() => walletAuth.setHide(!walletAuth?.hide)}
                    >
                      {!walletAuth?.hide ? (
                        <HiEyeOff
                          style={{ fontSize: "23px", cursor: "pointer" }}
                        />
                      ) : (
                        <HiEye
                          style={{ fontSize: "23px", cursor: "pointer" }}
                        />
                      )}
                    </IconButton>
                  </Typography>
                </Box>
              ) : (
                <Box mt={2} className="displaySpacebetween" align="center">
                  {userData?.user_type !== "page" && (
                    <Box align="left">
                      <Typography variant="h5">
                        {/* {social?.userRewardPoints ? (
                          <>{Number(social?.userRewardPoints)?.toFixed(2)}</>
                        ) : (
                          "0"
                        )} */}
                        {social?.userRewardPoints &&
                          numberCompactFormat(social?.userRewardPoints)}
                      </Typography>
                      <Typography variant="body2">Points</Typography>
                    </Box>
                  )}

                  <Box>
                    <Typography variant="h5">
                      {userData?.total_followers ? (
                        <>{makeFriendly(userData?.total_followers)}</>
                      ) : (
                        "0"
                      )}
                    </Typography>
                    <Typography variant="body2">Followers</Typography>
                  </Box>
                  <Box align="right">
                    <Typography variant="h5">
                      {userData?.total_following ? (
                        <>{makeFriendly(userData?.total_following)}</>
                      ) : (
                        "0"
                      )}
                    </Typography>
                    <Typography variant="body2">Following</Typography>
                  </Box>
                </Box>
              )}
            </Box>

            <Box mt={3}>
              <Divider
                style={{
                  background: "rgba(237, 237, 237, 0.15)",
                  width: "100%",
                }}
              />
            </Box>
          </Box>
          {type !== "crypto" && (
            <Hidden mdUp>
              <Box
                className={classes.searchBoxClass}
                onClick={() =>
                  history.push({ pathname: "/search", state: "sideBar" })
                }
              >
                <FormControl
                  fullWidth
                  className={`${classes.searchControl} placeholderColor`}
                >
                  <TextField
                    variant="outlined"
                    placeholder="Search......"
                    type="search"
                    value={social.search}
                    autoComplete="none"
                    autoFocus={false}
                    onChange={(e) => social.setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <IoSearchOutline />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>
            </Hidden>
          )}
          <Box
            py={2}
            style={{
              // overflowY: "scroll",
              // paddingBottom: "100px",

              overflow: "auto",
            }}
          >
            {checkFun.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          </Box>
        </Box>
        {type !== "crypto" && (
          <Box
            className="bottompositinBox"
            width="100%"
            onClick={() => {
              history.push(
                social?.userDataWallet?.wallet_pin === null
                  ? "/create-pin"
                  : social?.userDataWallet?.mobile_status === "0" ||
                    social?.userDataWallet?.email_status === "0"
                  ? "/verify-kyc"
                  : "/crypto-login"
              );
            }}
          >
            <Box className={classes.bottomImageBoxBox}>
              <Box align="left" className="bottomTextBox">
                <Typography variant="h5">
                  Explore the world on Tarality Crypto
                  {/* <MdOutlineArrowForwardIos style={{ color: "#fff" }} /> */}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <ConfirmationDialog
        open={open}
        handleClose={() => setOpen(false)}
        title={"LOGOUT"}
        desc={"Are you sure, you want to logout?"}
        // logoutHandler={logoutHandler}
      />
    </>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer, root: classes.root }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box className="drawerBoxMob" px={1}>
            <IconButton className="closeButtonNav" onClick={onMobileClose}>
              <HiChevronLeft
                style={{
                  color: "rgb(253, 199, 99)",
                  width: "25px",
                  height: "25px",
                  fontSize: "none",
                }}
              />
            </IconButton>
            {content}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer, root: classes.root }}
          open
          variant="persistent"
        >
          <Box p={1}>{content}</Box>
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
