import { Avatar, Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import moment from "moment";
import { IMAGE_PATH } from "src/constants";
import ViewStory from "../ViewStory";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
export default function StoryListData({ data, classes, social }) {
  const [openViewStory, setOpenViewStory] = useState(false);
  const [openViewStoryData, setOpenViewStoryData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [userData, setUserData] = useState({});

  const handleOpenStory = (data) => {
    setOpenViewStoryData(data?.user_stories);
    setOpenViewStory(true);
    setUserDetails(data);
    setUserData(data?.story_user_detail);
  };
  return (
    <Box className={classes.storyListMain}>
      {data && data?.user_stories[0]?.type === "video" ? (
        <>
          <Box
            style={{ width: "40px", height: "40px", cursor: "pointer" }}
            onClick={() => handleOpenStory(data)}
          >
            <video
              controls="false"
              autoPlay="true"
              loop
              muted
              playsinline="true"
              className="videoBox"
              style={{
                width: "100%",
                borderRadius: "100px",
                height: "40px",
                objectFit: "cover",
              }}
              poster="images/posterbanner.png"
              preload="none"
            >
              <source
                src={`${IMAGE_PATH}` + data?.user_stories[0]?.url}
                type="video/mp4"
              />
            </video>
          </Box>
          {/* <Avatar
            style={{ backgroundColor: "#1f1e1e", cursor: "pointer" }}
            onClick={() => handleOpenStory(data)}
            // src={data && `${IMAGE_PATH}` + data?.user_stories[0]?.url}
          >
            {" "}
            <PlayArrowIcon />
          </Avatar> */}
        </>
      ) : (
        <Avatar
          style={{ cursor: "pointer" }}
          onClick={() => handleOpenStory(data)}
          src={data && `${IMAGE_PATH}` + data?.user_stories[0]?.url}
        />
      )}

      <Box
        style={{ paddingLeft: "22px", cursor: "pointer" }}
        onClick={() => handleOpenStory(data)}
      >
        <Typography variant="h3">
          {data?.story_user_detail?.name
            ? data?.story_user_detail?.name
            : data?.story_user_detail?.user_name}
        </Typography>
        <Typography variant="h6">
          {moment(data?.user_stories[0]?.created_at).format("lll")}
        </Typography>
      </Box>
      <ViewStory
        openViewStory={openViewStory}
        closeViewStoryHandler={() => setOpenViewStory(false)}
        openViewStoryData={openViewStoryData}
        userDetails={userDetails}
        data={userData}
        social={social}
      />
    </Box>
  );
}
