import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
  Avatar,
  Hidden,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import { BiBell } from "react-icons/bi";
import { HiExternalLink } from "react-icons/hi";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";
import SettingsContext from "src/context/SettingsContext";
// import ConnectModal from "src/component/ConnectModal";
import { RxCross2 } from "react-icons/rx";
import { AiFillCaretDown } from "react-icons/ai";
import { withStyles } from "@material-ui/core/styles";
import { SocialContext } from "src/context/SocialAuth";
// import AnnouncementCard from "src/component/AnnouncementCard";
const headersData = [
  {
    label: "login",
    href: "/login",
  },
];
const announcement = [{}, {}, {}];
const useStyles = makeStyles((theme) => ({
  menubox: {
    "& ul": {
      width: "136px",
      borderRadius: "5px",
      backgroundColor: "#212121 !important",
      background: "#110720",
      "& a": {
        fontSize: "15px",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "500",
        color: "#B7B7B7",
        "&.active": {
          color: "#fff",
        },
        "&:hover": {
          color: "#fff",
        },
      },
    },
  },
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#FFFFFF",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    textDecoration: "none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      background:
        "linear-gradient(93.34deg, #FF6F37 6.82%, #FF2676 35.9%, #B801AA 68.08%, #7101BC 101.4%)",
      textShadow: "3px 3px 3px rgb(0 0 0 / 13%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "&:hover": {
      background:
        "linear-gradient(93.34deg, #FF6F37 6.82%, #FF2676 35.9%, #B801AA 68.08%, #7101BC 101.4%)",
      textShadow: "3px 3px 3px rgb(0 0 0 / 13%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  },
  menuButton1: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
    display: "flex",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "14px !important",
    fontWeight: "400",
    fontStyle: "normal",
    // letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    // borderBottom: "1px solid #3e3e3e",
    padding: "10px",
    "@media (max-width: 500px)": {
      // padding: "7px 0",
      width: "100%",
      fontSize: "14px",
      display: "flex",
      justifyContent: "center",
      background: "rgba(255, 255, 255, 0.05)",
      borderRadius: "10px",
      padding: "16px 0px",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "#fff",
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  drawerPaper: {
    width: "100%",
    height: "100%",
  },
  IconButtonBox: {
    position: "absolute",
    top: "0",
    right: "0",
  },
}));

export default function Header() {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const handleClose1 = () => {
  //   setAnchorEl(null);
  // };
  const social = useContext(SocialContext);
  const { setOpenLogin, setOpenSignUp } = social;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [open11, setOpen11] = React.useState(false);

  const handleDrawerOpen11 = () => {
    setOpen11(true);
  };

  const handleDrawerClose11 = () => {
    setOpen11(false);
  };
  const classes = useStyles();
  const StyledMenu = withStyles({
    paper: {
      // border: "1px solid #d3d4d5",
      marginTop: "20px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    menubox,
    drawerContainer,
    drawericon,
    IconButtonBox,
    logoDrawer,
    containerHeight,
    mainHeader,
    wallet,
    submenu,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {window.localStorage.getItem("token") ? (
              <Button
                variant="contained"
                className="signupButton"
                color="secondary"
                onClick={() => history.push("/home")}
              >
                Get Started
              </Button>
            ) : (
              <>
                <Button
                  variant="h6"
                  color="primary"
                  style={{ color: "#fff", cursor: "pointer" }}
                  onClick={() => setOpenLogin(true)}
                >
                  Login
                </Button>
                <Button
                  variant="contained"
                  className="signupButton"
                  color="secondary"
                  style={{ marginLeft: "17px" }}
                  onClick={() => setOpenSignUp(true)}
                >
                  Sign Up
                </Button>
              </>
            )}
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Hidden xsDown>
          <Drawer
            {...{
              anchor: "right",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <div className={drawerContainer}>
              <img className={logoDrawer} src="/images/logo.png" alt="" />
              {getDrawerChoices()}
            </div>
          </Drawer>
        </Hidden>
        <Hidden smUp>
          <SwipeableDrawer
            {...{
              anchor: "bottom",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={drawerContainer}>
              <div className={classes.IconButtonBox}>
                <IconButton onClick={() => handleDrawerClose()}>
                  <RxCross2 />
                </IconButton>
              </div>
              <img className={logoDrawer} src="/images/logo.png" alt="" />
              {getDrawerChoices()}
            </div>
          </SwipeableDrawer>
        </Hidden>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
            <Box className={drawericon}>
              {/* <Box
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleClick}
                mr={1}
              >
                <Avatar src="images/mgpro.png" onClick={handleClick} />
                <AiFillCaretDown style={{ color: "#fff", fontSize: "15px" }} />
              </Box> */}
              <IconButton
                {...{
                  edge: "start",
                  color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ color: "orange", fontSize: "30px" }}
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            {/* <MenuItem className={menuMobile}>{label}</MenuItem> */}
          </Button>
          {social.userLoggedIn ? (
            <Button
              variant="contained"
              className="signupButton"
              color="secondary"
              onClick={() => history.push("/home")}
              style={{ margin: "0px 16px" }}
            >
              Get Started
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                className="signupButton"
                color="secondary"
                style={{ marginLeft: "17px", marginTop: "20px" }}
                onClick={() => setOpenLogin(true)}
              >
                Login
              </Button>
              <Button
                variant="contained"
                className="signupButton"
                color="secondary"
                style={{ marginLeft: "17px", marginTop: "20px" }}
                onClick={() => setOpenSignUp(true)}
              >
                Sign Up
              </Button>
            </>
          )}
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <NavLink
            exact
            {...{
              key: label,
              color: "inherit",
              to: href,
              className: menuButton,
              activeClassName: "active",
            }}
          >
            {label}
          </NavLink>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{
          border: "none",
          background: "rgba(255, 255, 255, 0.04)",
          backdropFilter: "blur(21.5px)",
        }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={open11}
        onClose={handleDrawerClose11}
        style={{ overflowY: "scroll" }}
      >
        {/* <Box style={{ maxWidth: "355px", width: "100%", padding: "10px" }}>
          <Box align="center" py={2}>
            <Typography variant="h4">Announcements</Typography>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              {announcement.map(() => (
                <AnnouncementCard />
              ))}
            </Grid>
          </Grid>
          <Box align="right">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "10px 0px" }}
              onClick={() => history.push("/view-all-announcement")}
            >
              View More
            </Button>
          </Box>
        </Box> */}
      </Drawer>
      {/* <ConnectModal setOpen={setOpen} open={open} /> */}
    </>
  );
}
