import {
  Gif,
  Grid,
  SearchBar,
  SearchContext,
  SearchContextManager,
  SuggestionBar,
} from "@giphy/react-components";
import React, { useState, useContext } from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  searchClx: {
    top: "15px",
    height: "40px",
    position: "absolute",
    width: "89%",
    zIndex: "1",
    background: "#171717",
    "& .css-2hjmoj": {
      display: "none",
    },
    "& .css-zvkive": {
      background: "#1e1d1d",
      height: "35px",
      color: "white",
    },
    "& .css-5kpke2": {
      background: "#1e1d1d",
    },
  },
  suggestionClx: {
    "& .css-18dls1g": {
      marginTop: "30px !important",
      maxWidth: "500px",
      marginBottom: "-15px",
    },
    "& .css-1fbn05j": {
      fontFamily: "'Nunito Sans', sans-serif",
      fontStyle: "normal",
    },
  },
  pointer: {
    cursor: "pointer",
  },
}));

const Components = ({ onGifClick, classes }) => {
  const { fetchGifs, searchKey } = useContext(SearchContext);
  return (
    <>
      <Box className={classes.searchClx}>
        <SearchBar placeholder={"Search..."} />
      </Box>
      <Box className={classes.suggestionClx}>
        <SuggestionBar />
      </Box>
      <Box className={classes.pointer}>
        <Grid
          onGifClick={onGifClick}
          key={searchKey}
          columns={5}
          width={500}
          fetchGifs={fetchGifs}
          hideAttribution={true}
          noLink={true}
          noResultsMessage={"No result found"}
        />
      </Box>
    </>
  );
};
function GridDemo({ onGifClick }) {
  const classes = useStyles();
  return (
    <>
      <SearchContextManager apiKey="sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh">
        <Components onGifClick={onGifClick} classes={classes} />
      </SearchContextManager>
    </>
  );
}
const Gifphy = ({ selectGif, setComment, closeAnchorElGif }) => {
  const [modalGif, setModalGif] = useState();
  return (
    <>
      <GridDemo
        onGifClick={(gif, e) => {
          e.preventDefault();
          selectGif(gif.images.fixed_height_small.url);
          setComment(gif.images.fixed_height_small.url);
          closeAnchorElGif();
          // setModalGif(gif);
        }}
      />
      {modalGif && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, .8)",
          }}
          onClick={(e) => {
            e.preventDefault();
            setModalGif(undefined);
          }}
        >
          <Gif gif={modalGif} width={200} />
        </div>
      )}
    </>
  );
};

export default Gifphy;
