import React, { useContext, useState } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  IconButton,
  FormHelperText,
  ButtonGroup,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { SocialContext } from "src/context/SocialAuth";
import CloseIcon from "@material-ui/icons/Close";
export default function ConfirmationDialog({
  title,
  desc,
  isLoading,
  open,
  handleClose,
  logoutHandler,
  actionHandlerAPI,
  setReason,
  reason,
  note,
  tabView,
  setTabView,
  errorMsg,
}) {
  const history = useHistory();
  const social = useContext(SocialContext);

  return (
    <div>
      {title === "LOGOUT" ||
      title === "Withdraw" ||
      title === "Delete Bank Account" ||
      title === "Cancel Order" ||
      title === "Deposit request" ||
      title === "Claim Reward" ||
      title === "Submit your work" ||
      title === "Payment" ? (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <div className="dialogpurpleShade"></div>
          <div className="dialogpinkShade"></div>
          <DialogContent style={{ position: "relative" }}>
            <Box style={{ position: "absolute", top: "0px", right: "0px" }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box align="center" my={3}>
              <Typography variant={title === "Submit your work" ? "h4" : "h2"}>
                {title}
              </Typography>
            </Box>
            <Box align="center" my={note !== undefined ? 1 : 3}>
              <Typography variant="h5" style={{ fontSize: "17px" }}>
                {desc}
              </Typography>
            </Box>
            {note !== undefined && (
              <Box>
                <ul
                  style={{
                    padding: "0px",
                    marginLeft: "23px",
                    marginTop: "2px",
                  }}
                >
                  <li>
                    <Typography variant="body2">{note}</Typography>
                  </li>
                </ul>
              </Box>
            )}
            <Box my={3} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                onClick={handleClose}
                style={{ marginRight: "8px" }}
              >
                No
              </Button>
              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                onClick={logoutHandler}
                style={{ marginleft: "8px" }}
              >
                Yes {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <div className="dialogpurpleShade"></div>
          <div className="dialogpinkShade"></div>
          <DialogContent>
            <Box align="center" my={3}>
              {title === "Accept job" && (
                <Typography variant="h3">Mark this job as</Typography>
              )}
              {title === "Accept job" ? (
                <Box className="displayCenter" mt={2}>
                  {/* <Typography
                    variant="h3"
                    className={
                      tabView === "accept" ? "activeClx" : "inactiveClx"
                    }
                    onClick={() => setTabView("accept")}
                  >
                    Completed
                  </Typography>
                  &nbsp;
                  <Typography variant="h3">/</Typography>&nbsp;
                  <Typography
                    variant="h3"
                    className={
                      tabView === "reject" ? "activeClx" : "inactiveClx"
                    }
                    onClick={() => setTabView("reject")}
                  >
                    Not Completed!
                  </Typography> */}

                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    aria-label="contained primary button group"
                  >
                    <Button
                      // className={tabView === "accept" ? "activeBtn" : ""}
                      onClick={() => setTabView("accept")}
                      // active={tabView === "accept" ? "activeBtn" : ""}
                    >
                      Completed
                    </Button>
                    <Button
                      // // color="primary"
                      // className={tabView === "reject" ? "activeBtn" : ""}
                      onClick={() => setTabView("reject")}
                      // active={tabView === "reject" ? "activeBtn" : ""}
                      style={{ borderColor: "#911818" }}
                    >
                      {" "}
                      Not Completed
                    </Button>
                  </ButtonGroup>
                </Box>
              ) : (
                <Typography variant="body2">{title}</Typography>
              )}
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h5">{desc}</Typography>
              {tabView !== "accept" && (
                <>
                  <TextField
                    variant="standard"
                    value={reason}
                    fullWidth
                    placeholder="Enter reason"
                    style={title === "Accept job" ? { marginTop: "20px" } : {}}
                    onChange={(e) => setReason(e.target.value)}
                  />
                  <FormHelperText error>{errorMsg}</FormHelperText>
                </>
              )}
            </Box>
            <Box my={3} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                onClick={handleClose}
                style={{ marginRight: "8px" }}
              >
                Cancel
              </Button>

              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                onClick={actionHandlerAPI}
                style={{ marginleft: "8px" }}
              >
                {isLoading ? <ButtonCircularProgress /> : "Confirm"}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
