import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Avatar,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import Stories from "react-insta-stories";
import { IMAGE_PATH } from "src/constants";
import { IoClose } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { SocialContext } from "src/context/SocialAuth";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  mainbox: {
    "& .minbox": {
      width: "90px",
      height: "7px",
      background: "#e9aa4f",
      marginRight: "3px",
    },
    "& .minbox1": {
      width: "90px",
      height: "7px",
      background: "#D9D9D9",
      marginRight: "3px",
    },
  },
  positionsec: {
    position: "absolute !important",
    zIndex: "9999 !important",
    right: "42px !important",
    bottom: "39px !important",
    cursor: "pointer !important",
    background: "#453c3c",
    borderRadius: "100%",
    "& button": {
      cursor: "pointer !important",
      "& svg": {
        fontSize: "22px",
      },
    },
  },
}));

export default function ViewStory({
  openViewStory,
  closeViewStoryHandler,
  openViewStoryData,
  userDetails,
  handleDeleteStory,
  deleteLoading,
  type,
}) {
  const classes = useStyles();
  const social = useContext(SocialContext);
  const dataList = Array.isArray(openViewStoryData)
    ? openViewStoryData &&
      openViewStoryData?.map((data) => {
        let obj = {
          url: `${IMAGE_PATH}${data?.url}`,
          type: data?.type,
          duration: 3000,
        };
        return obj;
      })
    : [
        {
          url: `${IMAGE_PATH}${openViewStoryData?.url}`,
          type: openViewStoryData?.type,
          duration: 3000,
        },
      ];
  const handleCloseModal = () => {
    closeViewStoryHandler();
  };

  return (
    <Dialog
      open={openViewStory}
      onClose={closeViewStoryHandler}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <IconButton
          className="cancelBtn"
          onClick={() => {
            closeViewStoryHandler();
          }}
        >
          <IoClose />
        </IconButton>
        <Box className={classes.mainbox}>
          <Box display="flex" alignItems="center">
            <Avatar
              src={
                userDetails?.story_user_detail
                  ? `${IMAGE_PATH}` +
                    userDetails?.story_user_detail?.profile_url
                  : "images/Post/profile.png"
              }
            />
            <Box ml={1.5}>
              <Typography variant="h5" style={{ color: "#fff" }}>
                {userDetails?.story_user_detail?.name}
              </Typography>
              <Typography
                variant="body2"
                component="small"
                style={{ color: "#FDC763" }}
              >
                {moment(userDetails?.created_at).startOf("").fromNow()}
              </Typography>
            </Box>
          </Box>
          {type === "profile" && (
            <>
              {userDetails?.user_id == social?.userData?.user_id && (
                <Box
                  className={classes.positionsec}
                  onClick={() => {
                    !deleteLoading && handleDeleteStory();
                  }}
                >
                  <IconButton>
                    <MdDelete />
                  </IconButton>
                </Box>
              )}
            </>
          )}
          <Box mt={1} className="instastory">
            <Stories
              stories={dataList}
              defaultInterval={1500}
              onAllStoriesEnd={handleCloseModal}
              width="100%"
              height={468}
            />
          </Box>
          {/* {social.userData.user_id !==
            userDetails?.story_user_detail?.user_id && (
            <Box display="flex" alignItems="center" pt={1.5}>
              <Box mr={1}>
                <Avatar src={`${IMAGE_PATH}` + social.userData.profile_url} />
              </Box>

              <TextField
                fullWidth
                variant="outlined"
                placeholder="Write a comment..."
              />
              <Box ml={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn1}
                >
                  <SendIcon />
                </Button>
              </Box>
            </Box>
            )} */}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
