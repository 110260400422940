import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Avatar,
  IconButton,
  Divider,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { IoClose } from "react-icons/io5";
import { DropzoneArea } from "material-ui-dropzone";
import { useContext } from "react";
import { SocialContext } from "src/context/SocialAuth";
import {
  IMAGE_PATH,
  MUSIC_BASE_URL,
  RAPID_API_KEY,
  RAPID_API_HOST,
} from "src/constants";
import { dataPostHandler } from "src/Apiconfigs/service";
import toast from "react-hot-toast";
import ApiConfig from "src/Apiconfigs/config";
import axios from "axios";
import MusicPlayer from "src/MusicPlayer";
import ButtonCircularProgress from "./ButtonCircularProgress";
import useDebounce from "src/customHook/Debounce";
const useStyles = makeStyles((theme) => ({
  mainmodalBox: {
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },
    "& .addphotos": {
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      // padding: "75px 20px",
      background: "rgba(217, 217, 217, 0.25)",
      border: "1px solid #fff",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "7px",
      // border: "1px dashed",
      cursor: "pointer",
      position: "relative",
      "& input": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",

        cursor: "pointer",
      },
      "& svg": {
        fontSize: "30px",
        cursor: "pointer",
      },
      "& .MuiDropzoneArea-root": {
        border: "none",
        backgroundColor: "rgba(217, 217, 217, 0.25) !important",
      },
      "& .MuiDropzoneArea-text": {
        opacity: "1",
      },
      "& .MuiDropzonePreviewList-removeButton": {
        top: "7px",
        right: "43px",
        width: "30px",
        height: "30px",
      },
      "& .MuiFab-root": {
        minHeight: "30px",
      },
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#D1913C",
      fontWeight: "700",
    },
  },

  emojiBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    position: "relative",
    "& .EmojiPickerReact": {
      backgroundColor: "#252525",
    },
    "& .EmojiPickerReact .epr-header .epr-header-overlay": {
      display: "none",
    },
    "& .EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label": {
      backgroundColor: "#252525",
      height: "20px",
    },
    "& aside.EmojiPickerReact.epr-main": {
      borderStyle: "none",
    },
    "& .EmojiPickerReact .epr-preview": {
      display: "none",
    },
  },
  musicBox: {
    maxHeight: "200px",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: "10px",
  },
  searchBox: {
    marginTop: "10px",
    display: "flex",
    width: "100%",
    justifyContent: "end",
  },
}));

export default function AddTadak({
  businessId,
  openAddStory,
  setOpenAddStory,
}) {
  const classes = useStyles();
  const social = useContext(SocialContext);
  const [imgBlob1, setImgBlob1] = useState("");
  const [imgBlob2, setImgBlob2] = useState("");
  const [filedata, setFile] = useState("");
  const [thumnail, setThumnail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectMusic, setSelectedMusic] = useState({});
  const [songs, setSongs] = useState([]);
  const [searchMusic, setSearchMusic] = useState("");
  const [laodSongs, setLoadSongs] = useState(false);
  const [isSongLodaing, setIsLodaing] = useState(true);
  const deb = useDebounce(searchMusic, 2000);
  const clearImage = () => {
    setImgBlob1("");
    setFile("");
  };
  const musicListAPI = async (search) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${MUSIC_BASE_URL}search?q=${search}`,
        headers: {
          "X-RapidAPI-Key": RAPID_API_KEY,
          "X-RapidAPI-Host": RAPID_API_HOST,
        },
      });
      setSongs(response.data.data);
      setIsLodaing(false);
    } catch (error) {
      console.log(error);
      setIsLodaing(false);
    }
  };

  // useEffect(() => {
  //   const artistArray = [
  //     "arijit",
  //     "neha kakkar",
  //     "jassi gill",
  //     "sharry maan",
  //     "gurdas maan",
  //     "honey singh",
  //     "guru randhawa",
  //     "sonu nigam",
  //     "garry sandhu",
  //     "armaan malik",
  //     "khesari lal yadav",
  //     "atif aslam",
  //     "sunidhi chauhan",
  //     "shreya ghoshal",
  //     "mohammed rafi",
  //     "udit narayan",
  //     "sukhwinder singh",
  //     "shankar mahadevan",
  //     "sidhu moose wala",
  //     "ammy virk",
  //     "ranjit bawa",
  //     "babbu maan",
  //     "diljit dosanjh",
  //   ];

  //   const random = Math.floor(Math.random() * artistArray.length);
  //   console.log(random, artistArray[random]);
  //   const searchKey = searchMusic !== "" ? searchMusic : artistArray[random];
  //   musicListAPI(searchKey);
  // }, [deb, laodSongs]);
  const handleUploadFiles = async (filedata) => {
    var formdata = new FormData();
    formdata.append("image", filedata);
    try {
      const response = await axios({
        method: "POST",
        url: ApiConfig.uploadshort,
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
        data: formdata,
      });
      if (response.status === 200) {
        const splitImg = response.data.data?.Key; //main network
        return splitImg;
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error(err.response);
    }
  };

  const createShortAPIHandler = async () => {
    setErrorMsg("");
    if (filedata == "") {
      setErrorMsg("Please choose a file");
    } else {
      try {
        setIsLoading(true);
        let gethumbURL;
        const getMediaURL = await handleUploadFiles(filedata[0]);
        if (thumnail[0]) {
          const thumbnail = await handleUploadFiles(thumnail[0]);
          gethumbURL = thumbnail;
        }
        const response = await dataPostHandler("createshort", {
          url: getMediaURL,
          thumbnail_url: gethumbURL,
          business_page_id: businessId ? businessId : undefined,
          song_meta_data: selectMusic,
        });

        if (response) {
          toast.success("Short has been created successfully.");
          setOpenAddStory(false);
          setIsLoading(false);
          setSearchMusic("");
          setSelectedMusic({});
          clearImage();
          social.getShortDatahandler(localStorage.getItem("token"));
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => setErrorMsg(""), 2000);
  }, [errorMsg]);

  return (
    <Box>
      <Dialog
        open={openAddStory}
        onClose={() => setOpenAddStory(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <Box align="center">
          <Box mt={2} mb={2}>
            <Typography variant="h6">Create Short</Typography>
          </Box>
        </Box>
        <Box>
          <Divider
            style={{
              borderBottom: " 1px solid rgba(237, 237, 237, 0.25)",
              width: "90%",
              margin: "auto",
              background: "none",
            }}
          />
        </Box>
        <Box mt={2} ml={3} display="flex" alignItems="center">
          <Avatar src={`${IMAGE_PATH}` + social?.userData?.profile_url} />
          <Box ml={1.5}>
            <Typography variant="h5">{social?.userData?.name}</Typography>
          </Box>
        </Box>
        <IconButton
          className={classes.cancelBtn}
          onClick={() => setOpenAddStory(false)}
        >
          <IoClose />
        </IconButton>
        <DialogContent>
          <Box className={classes.mainmodalBox}>
            <Box className="addphotos">
              {imgBlob1 == "" ? (
                <>
                  <DropzoneArea
                    onChange={(files) => {
                      if (files.length !== 0) {
                        setFile(files);

                        setImgBlob1(URL.createObjectURL(files[0]));
                      } else {
                        setFile([]);

                        setImgBlob1("");
                      }
                    }}
                    dropzoneText="Add photos/Video"
                    acceptedFiles={["image/*,.mp4"]}
                    filesLimit={1}
                    maxFileSize="100000000"
                    showAlerts={false}
                  />
                </>
              ) : (
                <>
                  {filedata[0]?.type.split("/")[0] === "image" ? (
                    <img
                      src={imgBlob1}
                      width="100%"
                      style={{ borderRadius: "5px" }}
                    />
                  ) : (
                    <>
                      <video
                        controls="false"
                        autoPlay="true"
                        loop
                        muted
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "300px",
                        }}
                        playsinline="true"
                        className="videoBox"
                      >
                        <source src={imgBlob1} type="video/mp4" />
                      </video>
                      {imgBlob2 === "" ? (
                        <DropzoneArea
                          onChange={(files) => {
                            if (files.length !== 0) {
                              setThumnail(files);

                              setImgBlob2(URL.createObjectURL(files[0]));
                            } else {
                              setThumnail([]);

                              setImgBlob2("");
                            }
                          }}
                          dropzoneText="Add thumbnail"
                          acceptedFiles={["image/*"]}
                          filesLimit={1}
                          maxFileSize="100000000"
                          showAlerts={false}
                        />
                      ) : (
                        <>
                          {thumnail[0]?.type.split("/")[0] === "image" && (
                            <img
                              src={imgBlob2}
                              width="100%"
                              style={{
                                borderRadius: "5px",
                                maxWidth: "250px",
                                marginRight: "5px",
                              }}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
            <FormHelperText error>{errorMsg}</FormHelperText>

            {/* <MusicListTadak /> */}
            <Box className={classes.searchBox}>
              <TextField
                variant="outlined"
                placeholder="Search by song and artist name."
                value={searchMusic}
                onChange={(e) => setSearchMusic(e.target.value)}
              />
            </Box>
            <Box className={classes.musicBox}>
              <MusicPlayer
                songs={songs}
                laodSongs={laodSongs}
                selectMusic={selectMusic}
                setSongs={(item) => setSongs(item)}
                setSearchMusic={(item) => setSearchMusic(item)}
                setLoadSongs={(item) => setLoadSongs(item)}
                setSelectedMusic={(item) => setSelectedMusic(item)}
                isSongLodaing={isSongLodaing}
              />
            </Box>

            <Box mt={3} align="center">
              {imgBlob1 !== "" && (
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isLoading}
                  onClick={() => clearImage()}
                >
                  Remove
                </Button>
              )}
              &nbsp;
              <Button
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => createShortAPIHandler()}
              >
                Post {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
