import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  SvgIcon,
  Container,
} from "@material-ui/core";
import { HiMenu } from "react-icons/hi";
import { AiOutlineMenuFold } from "react-icons/ai";
import { TopBarData } from "src/layouts/HomeLayout/TopBar";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  topbarMain: {
    border: "none",
    background: "transparent",
    backdropFilter: "blur(15px)",
    borderBottom: "1px solid #80808040",
  },
  toolbar: {
    padding: "10px 0px 10px 0px",
    [theme.breakpoints.down("sm")]: {
      // paddingRight: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "6px",
      paddingBottom: "6px",
      height: "35px",
      minHeight: "35px !important",
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, type, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const [stickyClass, setStickyClass] = useState("relative");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 5 ? setStickyClass("fixed") : setStickyClass("relative");
    }
  };
  return (
    <AppBar elevation={0} position="fixed" color="inherit" {...rest}>
      <Container>
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton onClick={onMobileNavOpen}>
              <AiOutlineMenuFold style={{ color: "#FDC763" }} />
            </IconButton>
          </Hidden>
          <TopBarData type={type} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
