import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import Apiconfigs from "src/Apiconfigs/config";
import { calculateTimeLeft } from "src/utils/index";
import { handleUploadFiles } from "src/utils";
// import io from "socket.io-client";
import { getDataHandlerAPI } from "src/Apiconfigs/service";
import { paramsVal, mediaCompressorHandler } from "src/utils";
import toast from "react-hot-toast";
import { chatSocket } from "src/Apiconfigs/config";
export const SocialContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}
// const socket = io.connect(chatSocket, paramsVal);
export default function SocialAuth(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  // const [socket, setSocket] = useState(undefined);
  const [userData, setUserData] = useState({});
  const [endTime, setEndtime] = useState();
  const [perPage, setperPage] = useState(10);
  const [page, setpage] = useState(1);
  const [timeLeft, setTimeLeft] = useState();
  const [postListData, setPostListData] = useState([]);
  const [followingListData, setFollowingListData] = useState([]);
  const [storyData, setStoryData] = useState([]);
  const [shortData, setShortData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [storyLoading, setStoryLoading] = useState(true);
  const [chatUserSingle, setChatUserSingle] = useState();
  const [search, setSearch] = useState("");
  const [userDataWallet, setUserDataWallet] = useState({});
  const [onlineUserData, setOnlineUserData] = useState([]);
  const [isLoadingOnline, setIsLoadingOnline] = useState(false);
  const [tabView, setTabView] = useState("Home");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPostCreated, setIsPostCreated] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [userRewardPoints, setUserRewardPoints] = useState(false);
  const [createPostState, setCreatePostState] = useState({
    text: "",
    color: "",
    state: "",
  });

  const getUserProfileDatahandler = async (token) => {
    try {
      setIsLoading1(true);
      const res = await axios({
        method: "GET",
        url: Apiconfigs.profile,
        headers: {
          authorization: token,
        },
        params: {
          type: window.localStorage.getItem("type")
            ? window.localStorage.getItem("type")
            : null,
        },
      });

      if (res.status === 200) {
        const { data } = res.data;
        if (data.user_type == "user") {
          setUserData(data);
          const userProfileData = {
            token: window.localStorage.getItem("token"),
            username: data.username,
            profile_url: data.profile_url,
            name: data.name,
          };
          localStorage.setItem("mainUser", JSON.stringify(userProfileData));
        } else {
          const { page_details } = data;

          setUserData({
            user_id: data.user_id,
            email: page_details.email,
            name: page_details.name,
            username: page_details.name,
            biography: page_details.bio,
            profile_url: page_details.profile_pic,
            verified: "1",
            address: null,
            gender: "male",
            user_type: "page",
            cover_profile_url: page_details.cover_pic,
            country: page_details.country,
            country_code: null,
            state: page_details.state,
            mobile_number: page_details.phone_number,
            web_site: null,
            province: page_details.city,
            is_active_deactive: "1",
            admin_action: null,
            email_privacy: false,
            mobile_number_privacy: false,
            account_privacy: false,
            address_privacy: false,
            post_code: page_details.zip_code,
            is_reward_admob: "0",
            page_id: page_details.id,
            total_posts: data.total_posts,
            total_followers: data.total_followers,
            total_following: data.total_following,
            is_whitelist: 0,
            total_views: data.total_views,
            interest_data: [],
            total_likes: data.total_likes,
            rewards: 0,
            rating: 0,
            influencer_data: null,
            user_verfication_status: null,
            ver_limit: 200,
            inf_limit: 200,
          });
        }
        setIsLoading1(false);
        if (!window.localStorage.getItem("type")) {
          getUserSocialLogin(token, res?.data?.data?.username);
        }
      }
      setIsLoading1(false);
    } catch (error) {
      setIsLoading1(false);
      console.log(error);
      if (error.response) {
        if (error.response.data.statusCode == "404") {
          window.localStorage.removeItem("token");
        }
      }
    }
  };

  const getHomePagePostList = async (page, perPage, OLD_DATA, newPost) => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs[`${tabView == "Home" ? "postList" : "followingPost"}`],
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
        params:
          newPost !== undefined
            ? {
                page: page,
                perPage: perPage,
                sortBy: "created_at",
              }
            : {
                page: page,
                perPage: perPage,
              },
      });
      if (res?.data?.data?.length !== 0) {
        setIsLoading(false);
        let result = res?.data?.data;
        let temp = OLD_DATA ? OLD_DATA : [];
        setPostListData([...temp, ...result]);
        setperPage(res?.data?.meta.perPage);
        setpage(res?.data?.meta.page);
        setTotalPages(res.data.meta.pages);
        setCurrentPage(res.data.meta.page);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (error.response) {
        if (error.response.data.statusCode == "404") {
          window.localStorage.removeItem("token");
        }
      }
    }
  };

  const getUserSocialLogin = async (token, username) => {
    try {
      const res = await axios({
        method: "POST",
        url: Apiconfigs.loginSocial,
        headers: {
          authorization: token,
        },
        data: {
          username: username,
        },
      });
      if (res.data.status) {
        setUserData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.data.statusCode == "404") {
          window.localStorage.removeItem("token");
        }
      }
    }
  };

  const getStoryDatahandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.list,
        headers: {
          authorization: token,
        },
      });

      if (res.status === 200) {
        setStoryData(res?.data?.data);
        setStoryLoading(false);
      }
      setStoryLoading(false);
    } catch (error) {
      setStoryLoading(false);
      console.log(error);
      if (error.response) {
        if (error.response.data.statusCode == "404") {
          window.localStorage.removeItem("token");
        }
      }
    }
  };
  const getShortDatahandler = async (page, perPage, OLD_DATA) => {
    try {
      setIsLoading1(true);
      const res = await axios({
        method: "GET",
        url: Apiconfigs.listshort,
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
        params: {
          page: page,
          perPage: perPage,
        },
      });

      if (res.status === 200) {
        let result = res?.data?.data;
        let temp = OLD_DATA ? OLD_DATA : [];
        setShortData([...temp, ...result]);
        setperPage(res?.data?.meta.perPage);
        setpage(res?.data?.meta.page);
        setIsLoading1(false);
      }
      setIsLoading1(false);
    } catch (error) {
      setIsLoading1(false);
      console.log(error);
      if (error.response) {
        if (error.response.data.statusCode == "404") {
          window.localStorage.removeItem("token");
        }
      }
    }
  };
  const getUserProfileWallet = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.profile1,
        headers: {
          authorization: token,
        },
      });

      if (res.status === 200) {
        setUserDataWallet(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.data.statusCode == "404") {
          window.localStorage.removeItem("token");
        }
      }
    }
  };
  const getUserClaimRewardsPoint = async () => {
    try {
      const res = await getDataHandlerAPI(
        "rewards_points",
        window.localStorage.getItem("token")
      );

      if (res.status === 200) {
        setUserRewardPoints(res.data.data.points);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getShortDatahandler1 = async (page, perPage) => {
    getShortDatahandler(page, perPage);
  };

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    if (window.localStorage.getItem("token")) {
      getUserProfileDatahandler(window.localStorage.getItem("token"));
      getStoryDatahandler(window.localStorage.getItem("token"));
      if (!window.localStorage.getItem("type")) {
        getUserProfileWallet(window.localStorage.getItem("token"));
      }
      getShortDatahandler(1);
      getUserClaimRewardsPoint();
    }
  }, [
    window.localStorage.getItem("token"),
    window.localStorage.getItem("type"),
  ]);
  useEffect(() => {
    if (window.localStorage.getItem("token")) {
      getHomePagePostList(1, 10, []);
    }
  }, [tabView, window.localStorage.getItem("token")]);
  // useEffect(() => {
  //   if (socket === undefined && window.localStorage.getItem("token")) {
  //     const connect = io.connect(chatSocket);
  //     console.log("connect", connect);
  //     setSocket(connect);
  //   }
  //   return () => socket?.disconnect();
  // }, [socket]);

  let data = {
    userLoggedIn: isLogin,
    userDataWallet,
    userData,
    postListData,
    isLoading,
    isLoading1,
    timeLeft,
    page,
    createPostState,
    setPostListData,
    setpage,
    setTimeLeft,
    setEndtime,
    storyData,
    shortData,
    chatUserSingle,
    search,
    setTabView,
    tabView,
    setFollowingListData,
    followingListData,
    // socket,
    setSearch: setSearch,
    onlineUserData,
    storyLoading,
    totalPages,
    currentPage,
    isPostCreated,
    openLogin,
    openSignUp,
    userRewardPoints,
    getUserClaimRewardsPoint: getUserClaimRewardsPoint,
    setOnlineUserData: setOnlineUserData,
    isLoadingOnline,
    setIsLoadingOnline: setIsLoadingOnline,
    getUserProfileWallet: getUserProfileWallet,
    setChatUserSingle: { setChatUserSingle },
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    setOpenLogin: (data) => setOpenLogin(data),
    setOpenSignUp: (data) => setOpenSignUp(data),
    getUserProfileDatahandler: (data, type) =>
      getUserProfileDatahandler(data, type),
    getStoryDatahandler: (data) => getStoryDatahandler(data),
    setCreatePostState: (data) => setCreatePostState(data),
    getShortDatahandler: (page, perPage, OLD_DATA) =>
      getShortDatahandler(page, perPage, OLD_DATA),
    getHomePagePostList: (page, perPage, OLD_DATA) =>
      getHomePagePostList(page, perPage, OLD_DATA),
    getShortDatahandler1: (page, perPage) =>
      getShortDatahandler1(page, perPage),
    getUserSocialLogin: (data, username) => getUserSocialLogin(data, username),
    // setSocket: (data) => setSocket(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    createPostHandler: (data) => handleFormSubmit(data),
  };

  const handleFormSubmit = async (data) => {
    setCreatePostState({
      text: "Your post is currently being uploaded...",
      color: "linear-gradient(108.43deg, #C7762B 0%, #FDC763 100%)",
      state: "pending",
    });
    console.log("im working");
    let finalArray = [];
    let tagsArry = [];
    if (data.type !== "edit") {
      for (let i = 0; i < data.filedata?.length; i++) {
        const ImageUrl = await mediaCompressorHandler(data.filedata[i]);
        const filetypedata =
          data.filedata[i].type === "video/mp4" ||
          data.filedata[i].type === "video/webp"
            ? "video"
            : "image";
        finalArray.push({
          type: filetypedata,
          url: ImageUrl,
          thumbnail_url: ImageUrl,
        });
      }
      tagsArry = data?.selectedTag?.map((item) => {
        return item?.split("#")[1];
      });
    } else {
      finalArray = data?.filedata;
      tagsArry = data?.selectedTag;
    }

    try {
      const response = await axios({
        method: "POST",
        url: Apiconfigs[
          data?.timeLine === "timeline"
            ? "post_timelines"
            : data.type === "edit"
            ? "editPost"
            : "createPost"
        ],
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
        data:
          data.type === "edit"
            ? {
                caption:
                  data?.timeLine === "timeline" ||
                  data?.postId?.shared_user_data
                    ? data.formattedValue
                    : "",
                post_id: data?.postId?.id,
                post_type: data.postType,
                business_page_id: data.businessId ? data.businessId : undefined,
                description:
                  data?.timeLine === "timeline" ||
                  data?.postId?.shared_user_data
                    ? ""
                    : data.formattedValue,
                type: data.checked ? "public" : "private",
                files: finalArray,
                tags: tagsArry,
                latitude: "0",
                longitude: "0",
                categories: data?.categories?.name
                  ? [data?.categories?.name]
                  : [],
                mentions: [
                  {
                    user_id: "0",
                    user_type: "user",
                  },
                ],
              }
            : {
                post_type: data.postType,
                business_page_id: data.businessId ? data.businessId : undefined,
                description: data.formattedValue,
                type: data.checked ? "public" : "private",
                files: finalArray,
                tags: tagsArry,
                latitude: "0",
                longitude: "0",
                categories: data?.categories?.name
                  ? [data?.categories?.name]
                  : [],
                mentions: [
                  {
                    user_id: "0",
                    user_type: "user",
                  },
                ],
              },
      });
      if (
        data?.timeLine === "timeline"
          ? response?.status === 200
          : response?.data
      ) {
        getHomePagePostList(1, postListData?.length, [], "new_post");
        setCreatePostState({
          text: "Your post has been uploaded successfully.",
          color: "#35aa2b",
          state: "successfull",
        });
        setIsPostCreated(true);
        setIsPostCreated(false);
      } else {
        setCreatePostState({
          text: response?.data?.message,
          color: "red",
          state: "successfull",
        });
      }
    } catch (err) {
      setCreatePostState({
        text: err?.response?.data?.message,
        color: "red",
        state: "successfull",
      });
      console.error(err);
    }
  };

  return (
    <SocialContext.Provider value={data}>
      {props.children}
    </SocialContext.Provider>
  );
}
