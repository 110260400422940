import { Typography, Box, Avatar, Button } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IMAGE_PATH } from "src/constants";
import { useHistory } from "react-router-dom";
import ReplyComment from "../PostCard/ReplyComment";
import CommentBox from "src/component/CommentBox";
import { BsArrowReturnRight } from "react-icons/bs";
import { getParticularObjectDataHanndler } from "../Apiconfigs/service/index";

const useStyles = makeStyles((theme) => ({
  UserBox: {
    display: "flex",
    "& h6": {
      fontWeight: "500",
      fontSize: "14px",
      color: "#FDC763",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
      "& span": {
        fontWeight: "400",
        color: "#ffff",
        fontSize: "12px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
      },
    },
    "& small": {
      color: "rgba(255, 255, 255, 0.5)",
      fontSize: "12px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
    },
    "& p": {
      color: "rgba(0, 0, 0, 0.5)",
      fontSize: "12px",
      marginTop: "5px",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    "& div": {
      // width: "100%",
      backgroundColor: "transparent",
    },
  },
  replyBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "-6px",
    marginLeft: "13px",
  },
}));
export default function (props) {
  const { data, index, type } = props;
  const classes = useStyles();
  const history = useHistory();
  const [replyModal, setReplyModal] = useState(false);
  const [openReplyListModal, setOpenReplyListModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [replyCommnetList, setReplyCommnetList] = useState([]);

  const getReplyCommentList = async (id) => {
    try {
      const response = await getParticularObjectDataHanndler(
        "list_sub_comment",
        { comment_id: id }
      );
      if (response.status === 200) {
        setReplyCommnetList(response.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (type !== "replyCmt") {
      getReplyCommentList(data?.id);
    }
  }, [data?.id]);

  function profileUrl(data) {
    return type === "replyCmt"
      ? data?.user_data?.profile_url
      : data?.commented_user_data?.profile_url;
  }

  return (
    <>
      <Box className={classes.UserBox} key={`commentBox${index}`}>
        <Box
          style={{ marginRight: "15px", cursor: "pointer" }}
          onClick={() =>
            history.push({
              pathname: "/view-othersprofile",
              search: data?.user_id?.toString(),
            })
          }
        >
          <Avatar src={`${IMAGE_PATH}` + profileUrl(data)} />
        </Box>
        <Box>
          <Typography
            variant="h6"
            onClick={() =>
              history.push({
                pathname: "/view-othersprofile",
                search: data?.user_id?.toString(),
              })
            }
          >
            {type === "replyCmt"
              ? data?.user_data?.name
              : data?.commented_user_data?.name}{" "}
          </Typography>
          <Typography>
            {data?.type === "text" && (
              <span style={{ wordBreak: "break-all", color: "#fff" }}>
                {data?.comment}
              </span>
            )}
          </Typography>
          <Box>
            {data?.type === "image" && (
              <img src={data?.comment} width="150px" />
            )}
          </Box>
        </Box>
      </Box>
      <Box mb={1} ml={5}>
        {type !== "replyCmt" && (
          <>
            <Button onClick={() => setReplyModal(!replyModal)}>Reply</Button>
            {!openReplyListModal && (
              <>
                {replyCommnetList?.length !== 0 && (
                  <Box className={classes.replyBox}>
                    <BsArrowReturnRight />
                    <Button onClick={() => setOpenReplyListModal(true)}>
                      {replyCommnetList?.length} replies
                    </Button>
                  </Box>
                )}
              </>
            )}
          </>
        )}

        {openReplyListModal && (
          <Box>
            {replyCommnetList &&
              replyCommnetList?.map((data, i) => {
                return <CommentBox data={data} index={i} type="replyCmt" />;
              })}
          </Box>
        )}
        {replyModal && (
          <ReplyComment
            data={data}
            getReplyCommentList={() => getReplyCommentList(data?.id)}
          />
        )}
      </Box>
    </>
  );
}
