import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Divider,
  IconButton,
} from "@material-ui/core";
import { AiOutlineMedium } from "react-icons/ai";
import { FaInstagram, FaFacebookF, FaTelegramPlane } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { FiTwitter } from "react-icons/fi";
import Logo from "src/component/Logo";
import { useHistory } from "react-router-dom";
import { AiFillStar, AiFillYoutube, AiFillLinkedin } from "react-icons/ai";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: "40px 0px 20px",
    background: "#110E10",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      textTransform: "uppercase",
      background: "linear-gradient(90.91deg, #CA772A 73.19%, #FFCA64 98.54%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      fontSize: "14px",
      fontWeight: "600",
    },
    "& .vectorImage": {
      position: "absolute",
      right: "9%",
      top: "5%",
      width: "100%",
      maxWidth: "250px",
    },
    "& .mainBox": {
      position: "relative",
      zIndex: "999",
      flexWrap: "wrap",
      "& .IconsBox": {
        padding: "15px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        "& .IconsButtonBox": {
          color: "#fff",
          background: "rgba(255, 255, 255, 0.04)",
          marginRight: "10px",
          padding: "12px",
          "& svg": {
            fontSize: "20px",
          },
          "&:hover": {
            background:
              "linear-gradient(108.43deg, #c7762b 0%, #fdc763 100%) !important",
          },
        },
      },
      "& .footerLinkPages": {
        display: "flex",
        alignItems: "center",
        padding: "11px 0",
        justifyContent: "center",
        position: "relative",
        flexWrap: "wrap",

        "& p": {
          color: "rgb(149, 149, 149)",
          "&:hover": {
            background:
              "linear-gradient(90.91deg, #CA772A 73.19%, #FFCA64 98.54%)",

            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          },
        },
        "& h4": {
          padding: "15px 0px",
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
        },
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Box className="mainBox displaySpacebetween">
            <Box>
              <Box align="left">
                <RouterLink to="/">
                  <Logo className="logoImg" />
                </RouterLink>
              </Box>
              <Box className="IconsBox">
                <IconButton
                  target="_blank"
                  href="https://www.facebook.com/TaralityCoin"
                  className="IconsButtonBox"
                >
                  <FaFacebookF />
                </IconButton>
                {/* <IconButton
                  target="_blank"
                  href="https://telegram.org/"
                  className="IconsButtonBox"
                >
                  <FaTelegramPlane />
                </IconButton> */}
                <IconButton
                  target="_blank"
                  href="https://www.instagram.com/taralitycoin/"
                  className="IconsButtonBox"
                >
                  <FaInstagram />
                </IconButton>
                <IconButton
                  target="_blank"
                  href="https://twitter.com/TaralityCoin"
                  className="IconsButtonBox"
                >
                  <FiTwitter />
                </IconButton>

                <IconButton
                  target="_blank"
                  href="https://bit.ly/44eygNE "
                  className="IconsButtonBox"
                >
                  <AiFillYoutube />
                </IconButton>

                <IconButton
                  target="_blank"
                  href="https://www.linkedin.com/company/tarality/"
                  className="IconsButtonBox"
                >
                  <AiFillLinkedin />
                </IconButton>
                {/* <IconButton
                  target="_blank"
                  href="https://medium.com/"
                  className="IconsButtonBox"
                >
                  <AiOutlineMedium />
                </IconButton> */}
              </Box>
              <Box className="footerLinkPages">
                <Box mr={2} style={{ cursor: "pointer" }}>
                  <Typography
                    variant="body2"
                    onClick={() => history.push("/about-us")}
                  >
                    About us
                  </Typography>
                </Box>

                <Box mx={2} style={{ cursor: "pointer" }}>
                  <Typography
                    variant="body2"
                    onClick={() => history.push("/privacy-policy")}
                  >
                    Privacy Policy
                  </Typography>
                </Box>
                <Box mx={2} style={{ cursor: "pointer" }}>
                  <Typography
                    variant="body2"
                    onClick={() => history.push("/terms-conditions")}
                  >
                    {" "}
                    Terms & Conditions
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box align="center">
              <img
                src="images/footer_1.png"
                alt="image"
                style={{ width: "auto", maxWidth: "170px" }}
              />
              <Box className="displayStart" mt={3}>
                {" "}
                <Typography variant="h3">PRODUCT</Typography>
                <AiFillStar style={{ margin: "0 10px" }} />
                <Typography variant="h3" style={{ fontWeight: "300" }}>
                  MAKE IN INDIA
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>

        <Box
          align="center"
          mt={2}
          style={{
            marginTop: "16px",
            width: "100%",
            borderTop: "1px solid #323232",
          }}
        >
          <Container maxWidth="lg">
            <Box align="center" mt={3} mb={2}>
              <Typography
                variant="body1"
                style={{ color: "#959595", fontSize: "14px" }}
              >
                {/* Copyright © 2023 LIMITED, ALL RIGHTS RESERVED */}
                {`Copyright©${moment().format(
                  "YYYY"
                )}. Created with love by`}{" "}
                <span>Tarality</span>
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
