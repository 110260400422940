import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Container, Grid } from "@material-ui/core";
import NavBar from "src/layouts/DashboardLayout/NavBar";
import TopBar from "src/layouts/DashboardLayout/TopBar";
import CustomTaost from "src/component/CustomToast";
import { WalletContext } from "src/context/WalletAuth";
import StorySideBar from "src/component/storysidebar";
import RecentHistory from "./RecentHistory";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    height: "100%",
    // overflow: "hidden",
    width: "100%",
    backgroundColor: "#090909",
  },
  wrapper: {
    position: "relative",
    zIndex: "9",
    width: "calc(100% - 240px)",
    [theme.breakpoints.up("lg")]: {},
    "@media (max-width:1279px)": { width: "100%" },
    "@media (max-width:599px)": {},
  },
  contentContainer: {
    flex: "1 1 auto",
    overflow: "auto",
    padding: "15px 0px 0px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: " 20px 0px",
    },
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    position: "relative",
    padding: "0px 0 60px",
    WebkitOverflowScrolling: "touch",
    [theme.breakpoints.down("sm")]: {
      zIndex: "9",
      overflow: "hidden",
    },
  },
  mainBox_layout: {
    display: "flex",
    width: "100%",
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const walletAuth = useContext(WalletContext);
  useEffect(() => {
    setTimeout(
      () =>
        walletAuth.setCustomToastData({
          state: false,
          text: "",
          color: "",
        }),
      5000
    );
  }, [walletAuth?.customToastData?.state]);
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} type="crypto" />

      <Container maxWidth="lg" className="mainMobileView">
        <div className={classes.mainBox_layout}>
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
            type="crypto"
          />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content} id="main-scroll">
                <Grid container spacing={1}>
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    {walletAuth?.customToastData?.state && (
                      <CustomTaost
                        text={walletAuth?.customToastData?.text}
                        color={walletAuth?.customToastData?.color}
                      />
                    )}
                    {children}
                  </Grid>
                  {/* <Grid item sm={12} xs={12} md={4} lg={3}>
                    <RecentHistory />
                  </Grid> */}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
