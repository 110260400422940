import {
  postAPIHandlerIdInPath,
  putAPIHandlerInpath,
} from "src/Apiconfigs/service";
import {
  Avatar,
  Box,
  Typography,
  makeStyles,
  IconButton,
  Paper,
  Button,
  LinearProgress,
  withStyles,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { BiBorderRadius } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import PoolProgressBar from "./poolsection/PoolProgressBar";
import { calculateTimeLeft } from "src/utils";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  pollCardBox: {
    position: "relative",
    "& .poolcontentBox": {
      [theme.breakpoints.down("xs")]: {
        padding: "20px",
      },
    },
  },
}));

export default function PoolCards({
  pollDetails,
  value,
  index,
  social,
  callBack,
}) {
  // console.log("pollDetails-----", pollDetails);
  const classes = useStyles();
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const addVoteHandler = async (name, num, id) => {
    const dataSend = {
      vote_1: name == "pool1" ? 1 : undefined,
      vote_2: name == "pool2" ? 1 : undefined,
      vote_3: name == "pool3" ? 1 : undefined,
      vote_4: name == "pool4" ? 1 : undefined,
    };
    try {
      const res = await putAPIHandlerInpath("updatepoll", id, dataSend);
      if (res) {
        toast.success(res.data.message);
        if (callBack) {
          callBack();
        } else {
          social.getHomePagePostList(1, social?.postListData?.length, []);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(
        calculateTimeLeft(
          new Date(
            moment(pollDetails?.pollDetails?.poll_end_date).unix() * 1000
          )
        )
      );
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <Paper elevation={1} className="main_postCardBox">
      <Box className={classes.pollCardBox}>
        <Box className="poolcontentBox">
          <Box mt={2} mb={2}>
            <Typography variant="h6" color="secondary">
              {pollDetails?.pollDetails?.poll_topic}
            </Typography>

            {pollDetails?.is_vote ? (
              <>
                <PoolProgressBar pollDetails={pollDetails} />
              </>
            ) : (
              <Box align="center" mt={3}>
                {pollDetails?.pollDetails?.poll_title_1 && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="poolButton"
                      onClick={() =>
                        addVoteHandler("pool1", 1, pollDetails?.id)
                      }
                    >
                      {pollDetails?.pollDetails?.poll_title_1}
                    </Button>
                  </>
                )}

                {pollDetails?.pollDetails?.poll_title_2 && (
                  <Box mt={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="poolButton"
                      onClick={() =>
                        addVoteHandler("pool2", 1, pollDetails?.id)
                      }
                    >
                      {pollDetails?.pollDetails?.poll_title_2}
                    </Button>
                  </Box>
                )}
                {pollDetails?.pollDetails?.poll_title_3 && (
                  <Box mt={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="poolButton"
                      onClick={() =>
                        addVoteHandler("pool3", 1, pollDetails?.id)
                      }
                    >
                      {pollDetails?.pollDetails?.poll_title_3}
                    </Button>
                  </Box>
                )}
                {pollDetails?.pollDetails?.poll_title_4 && (
                  <Box mt={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="poolButton"
                      onClick={() =>
                        addVoteHandler("pool4", 1, pollDetails?.id)
                      }
                    >
                      {pollDetails?.pollDetails?.poll_title_4}
                    </Button>
                  </Box>
                )}
              </Box>
            )}

            <Box mt={4} className="displayStart">
              <Typography variant="body2" style={{ color: "#fff" }}>
                {pollDetails?.pollDetails?.total_votes} votes
              </Typography>
              <span
                style={{
                  width: "5px",
                  height: "5px",
                  borderRadius: "50px",
                  color: "#fff",
                }}
              ></span>
              <span style={{ color: "#FDC763" }}>
                {" "}
                {timeLeft.days ? timeLeft.days && timeLeft.days : "0"}d :&nbsp;
                {timeLeft.hours ? timeLeft.hours && timeLeft.hours : "0"}h
                :&nbsp;
                {timeLeft.minutes ? timeLeft.minutes && timeLeft.minutes : "0"}m
                :&nbsp;
                {timeLeft.seconds ? timeLeft.seconds && timeLeft.seconds : "0"}s
                left
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
