import React from "react";
import { IMAGE_PATH } from "src/constants";
import { Typography, Box } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { BsFillPlayCircleFill } from "react-icons/bs";
export default function PostCardView({
  data,
  value,
  typeData,
  setModalData,
  setOpenModal,
  index,
  setIndexNo,
  videotype,
}) {
  return (
    <>
      {value?.post_type == "image" ? (
        <Box
          className="album-image"
          onClick={() => {
            if (typeData !== "modalcino") {
              setModalData(data?.id);
              setOpenModal(true);
              setIndexNo(index);
            }
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <LazyLoadImage
            effect="blur"
            src={`${IMAGE_PATH}` + value?.url}
            className="image-file"
          />
          {data?.post_meta_data?.length > 3 && (
            <Box className={data?.post_meta_data?.length >= 3 && "NewAfter"}>
              <Typography variant="h3" color="primary">
                +{data?.post_meta_data?.length - 3}
              </Typography>
            </Box>
          )}
        </Box>
      ) : value?.post_type == "text" ? (
        <Box
          className="album-image displayCenter"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (typeData !== "modalcino") {
              setModalData(data?.id);
              setOpenModal(true);
              setIndexNo(index);
            }
          }}
        >
          {data?.description}
        </Box>
      ) : (
        <Box className="album-image">
          <Box
            onClick={() => {
              if (typeData !== "modalcino") {
                setModalData(data?.id);
                setOpenModal(true);
                setIndexNo(index);
              }
            }}
          >
            <Box className="playButnSec">
              <BsFillPlayCircleFill />
            </Box>
            <video
              loop
              autoplay={videotype === "profile" ? false : true}
              webkit-playsinline="true"
              playsinline="true"
              // preload="none"
              muted={true}
              style={{
                height: "100%",
                width: "100%",
                cursor: "pointer",
              }}

              // poster={`${IMAGE_PATH}` + value?.thumbnail_url}
            >
              <source src={`${IMAGE_PATH}` + value?.url} type="video/webm" />
            </video>
          </Box>
          {data?.post_meta_data?.length > 3 && (
            <Box className={data?.post_meta_data?.length > 3 && "NewAfter"}>
              <Typography variant="h3" color="primary">
                +{data?.post_meta_data?.length - 3}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
