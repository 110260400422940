/* eslint-disable no-use-before-define */
import React, { useState, useContext, useEffect, useMemo } from "react";
import { matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  List,
  makeStyles,
  Avatar,
  Typography,
  Button,
  Tooltip,
} from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";
import { useHistory, Link } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { HiFlag } from "react-icons/hi";
import { MdLogout, MdSettings } from "react-icons/md";
import { FaUserEdit, FaHireAHelper } from "react-icons/fa";
import { FcInvite, FcDataEncryption } from "react-icons/fc";
import { SocialContext } from "src/context/SocialAuth";
import { IMAGE_PATH } from "src/constants";
import SuccessFullModal from "src/component/SuccessFullModal";
import io from "socket.io-client";
import ApiConfig, { chatSocket } from "src/Apiconfigs/config";
import { paramsVal } from "src/utils";
import { IoIosNotifications } from "react-icons/io";
import {
  getParticularObjectDataHanndler,
  getPerticularIdByPath,
} from "src/Apiconfigs/service";
import { toast } from "react-hot-toast";
import Axios from "axios";

const sections = [
  {
    title: "Settings",
    href: "/verify-myprofile",
    icon: MdSettings,
  },
  {
    title: "Notification",
    href: "/Notification",
    icon: IoIosNotifications,
  },
  {
    title: "Edit Profile",
    href: "/edit-profile",
    icon: FaUserEdit,
  },
  // {
  //   title: "My Pages",
  //   href: "/business",
  //   icon: HiFlag,
  // },
  {
    title: "Become an Influencer",
    href: "/apply-as-influencer",
    icon: FaUserEdit,
  },
  {
    title: "My Invitations",
    href: "/my-invitations",
    icon: FcInvite,
  },
  {
    title: "Hire Influencer",
    href: "/influencer-list",
    icon: FaHireAHelper,
  },
  // {
  //   title: "Explore Crypto",
  //   href: "/crypto-login",
  //   icon: FcDataEncryption,
  // },

  {
    title: "Logout",
    href: "/dashboard",
    icon: MdLogout,
  },
];
const Pagesections = [
  // {
  //   title: "Settings",
  //   href: "/verify-myprofile",
  //   icon: MdSettings,
  // },
  {
    title: "Notification",
    href: "/Notification",
    icon: IoIosNotifications,
  },
  // {
  //   title: "Edit Profile",
  //   href: "/edit-profile",
  //   icon: FaUserEdit,
  // },
  {
    title: "Logout",
    href: "/dashboard",
    icon: MdLogout,
  },
];
const sectionsCrypto = [
  {
    title: "Notification",
    href: "/Notification",
    icon: IoIosNotifications,
  },
  {
    title: "Logout",
    href: "/dashboard",
    icon: MdLogout,
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  paper: {
    // backgroundColor: "#FFFFFF !important",
    minWidth: "120px !important",
    boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.16)",
    // borderRadius: "5px",
    background: "rgba(32, 32, 32, 0.7)",
    border: "1px solid #3b3932",
    backdropFilter: "blur(150px)",
    borderRadius: "15px",
  },
  desktopDrawer: {
    position: "absolute",
    right: 0,
    top: 30,
    width: 349,
    minWidth: "120px !important",
    boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.16)",
    padding: "15px",
    background: "rgba(32, 32, 32, 0.7)",
    border: "1px solid #403b3159",
    // backdropFilter: "blur(150px)",
    borderRadius: "15px",
    [theme.breakpoints.only("xs")]: {
      width: "243px",
    },
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
    "@media (max-width: 767px)": {
      width: "37px",
      height: "37px",
    },
  },
  avatarBig: {
    cursor: "pointer",
    width: 150,
    height: 150,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  UserNameClass: {
    display: "flex",
    alignItems: "center",
    background: "rgba(255, 255, 255, 0.03)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backdropFilter: "blur(150px)",
    borderRadius: "15px",
    padding: "23px 14px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 14px",
    },

    "& .UserProfile": {
      width: "50px",
      display: "flex",
      alignItems: "center",
      position: "relative",
      cursor: "pointer",
      "& h6": {
        color: "#fff",
        whiteSpace: "pre",
      },
      "& .smallImage": {
        margin: "0px",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        position: "absolute",
        bottom: "0px",
        right: "10px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
          mixWidth: "100%",
          width: "auto",
          minHeight: "100%",
        },
      },
    },
    "& h6": {
      fontWeight: "600",
      marginLeft: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      "& p": {
        display: "flex",
        justifyContent: "center",
        marginRight: "16px",
      },
    },
  },
  termsPrivay: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      "& a": {
        color: "#918e8e87",
      },
    },
  },
  menuButtons: {
    justifyContent: "left",
    textTransform: "capitalize",
    color: "#404040",
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "25px",
    padding: "18px 0px",
    borderBottom: "1px solid rgba(237, 237, 237, 0.08)",
    borderRadius: "0px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px",
    },
    "& h5": {
      color: "#fff",
    },
    "& .circle": {
      marginRight: "20px",
      background: "rgba(255, 255, 255, 0.15)",
      borderRadius: "10px",
      padding: "7px",
      display: "flex",
    },
  },
  hideBox: {
    "@media(min-width:600px)": {
      display: "none",
    },
  },
  "& .UserProfile": {
    cursor: "pointer",
  },
}));
const UseProfileNavBar = ({ type }) => {
  const social = useContext(SocialContext);
  const userData = social?.userData;
  const listInfluencer = social?.userData?.influencer_data;
  const classes = useStyles();
  const history = useHistory();
  const [rightBar, setRightBar] = useState(false);
  const [open, setOpen] = useState(false);
  const [successfulOpen, setSuccessfulOpen] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  // const checkType = type === "crypto" ? sectionsCrypto : sections;
  const checkType = useMemo(() => {
    return type === "crypto"
      ? sectionsCrypto
      : userData?.user_type === "page"
      ? Pagesections
      : sections;
  }, [type, userData?.user_type]);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [pageListData, setPageListData] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);

  const handleLastSeenEvent = async () => {
    const socket = io.connect(chatSocket, paramsVal);
    socket.emit("last_seen", {
      user_data: social?.userData?.user_id,
    });
    socket.on("last_seen_response", (event) => {});
    socket.disconnect();
  };

  const getNotificationListAPIHandler = async () => {
    const dataSend = {
      page: 1,
      perPage: 10,
    };
    try {
      setNotificationList([]);
      const response = await getParticularObjectDataHanndler(
        type === "crypto" ? "walletnotifications" : "notifications",
        dataSend,
        window.localStorage.getItem("token")
      );
      if (response?.status === 200) {
        setNotificationList(
          type === "crypto"
            ? response?.data?.data?.history
            : response?.data?.data
        );
        setIsLoading(false);
        setNoOfPages(
          type === "crypto"
            ? response.data.data.totalPages
            : response.data.meta.pages
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getListPageAPIHandler = async () => {
    try {
      const response = await getParticularObjectDataHanndler("my_pages", {});
      if (response?.status === 200) {
        setPageListData(response.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setPageListData([]);
      }
    } catch (error) {
      console.log(error);
      setPageListData([]);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotificationListAPIHandler();
    getListPageAPIHandler();
  }, []);

  const logoutHandler = async (onDisconnect) => {
    try {
      setIsLoading(true);
      const response = await getParticularObjectDataHanndler("log_out", {
        type: "web",
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        setIsLoading(false);
        if (social?.userData?.user_id) {
          handleLastSeenEvent();
        }
        handleRemoveLocalData();
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
        handleRemoveLocalData();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
      handleRemoveLocalData();
    }
  };

  const handleRemoveLocalData = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("password");
    window.localStorage.removeItem("mainUser");
    window.sessionStorage.removeItem("userChatData");
    window.localStorage.removeItem("type");
    history.push("/");
  };

  const handlePageLogin = async (id) => {
    try {
      setIsPageLoading(true);
      const response = await Axios({
        method: "GET",
        url: `${ApiConfig.page_login}/${id}`,
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      });
      if (response.status === 200) {
        setIsPageLoading(false);
        window.localStorage.setItem("token", response.data.data.access_token);
        window.localStorage.setItem("type", "page");
        social.getUserProfileDatahandler(response.data.data.access_token);
        social.getHomePagePostList(1, 10, []);
        toast.success(response.data.message);
        history.push("/home");
      } else {
        toast.error(response.data.message);
        setIsPageLoading(false);
      }
    } catch (error) {
      setIsPageLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  function checkVerifyUser(type) {
    const filterData = type.filter((item, i) => {
      return item.title === "Become an Influencer"
        ? social?.userData?.user_verfication_status == "1" &&
            social?.userData?.total_followers >= social?.userData?.inf_limit &&
            listInfluencer?.is_paid != "1"
        : item.title === "Hire Influencer"
        ? social?.userData?.user_verfication_status == "1"
        : item.title === "My Invitations"
        ? listInfluencer?.is_paid == "1"
        : item;
    });
    return filterData;
  }
  const mainUserProfile = JSON.parse(window.localStorage.getItem("mainUser"));
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"LOGOUT"}
          desc={"Are you sure, you want to logout?"}
          isLoading={isLoading}
          logoutHandler={logoutHandler}
        />
      )}
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        className="walletbox"
      >
        <Box className={classes.UserNameClass}>
          <Box className="UserProfile">
            <Avatar
              src={
                userData.profile_url && `${IMAGE_PATH}` + userData.profile_url
              }
            />
            <Typography variant="h6">{userData?.username}</Typography>
          </Box>

          <Typography variant="body2">
            <Link to="/own-profile" style={{ color: "#56B3E8" }}>
              View own profile
            </Link>
          </Typography>
        </Box>

        {userData && userData.user_type !== "page" && (
          <>
            <Box>
              {pageListData &&
                pageListData.map((item, i) => {
                  return (
                    <Tooltip title="My pages">
                      <Box
                        className="UserProfile"
                        display="flex"
                        mt={1}
                        style={{ cursor: "pointer", alignItems: "center" }}
                        onClick={() => {
                          handlePageLogin(item?.id);
                          setRightBar(false);
                        }}
                      >
                        <Avatar
                          src={
                            item.profile_pic &&
                            `https://prod-taralgram.s3.ap-south-1.amazonaws.com/${item.profile_pic}`
                          }
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="h6">{item?.name}</Typography>
                      </Box>
                    </Tooltip>
                  );
                })}
            </Box>
          </>
        )}
        {userData && userData.user_type === "page" && (
          <Box>
            <Box
              className="UserProfile"
              display="flex"
              mt={1}
              style={{ cursor: "pointer", alignItems: "center" }}
              onClick={() => {
                window.localStorage.setItem("token", mainUserProfile.token);
                window.localStorage.removeItem("type");
                social.getUserProfileDatahandler(mainUserProfile.access_token);
                social.getHomePagePostList(1, 10, []);
                getListPageAPIHandler();
                history.push("/home");
                setRightBar(false);
              }}
            >
              <Avatar src={`${IMAGE_PATH}${mainUserProfile.profile_url}`} />
              &nbsp;&nbsp;&nbsp;
              <Typography variant="h6">{mainUserProfile?.username}</Typography>
            </Box>
          </Box>
        )}
        <Box>
          {checkType &&
            checkVerifyUser(checkType).map((section, i) => {
              const Icon = section.icon;
              return (
                <>
                  <Box
                    className={
                      section.title === "Notification" && classes.hideBox
                    }
                  >
                    <Button
                      fullWidth
                      className={classes.menuButtons}
                      key={i}
                      onClick={() => {
                        section.title === "Logout"
                          ? setOpen(true)
                          : section.title === "Become an Influencer"
                          ? history.push({
                              pathname:
                                listInfluencer?.is_paid == "1"
                                  ? setSuccessfulOpen(true)
                                  : listInfluencer?.is_verify === "0"
                                  ? "/influencer-pending"
                                  : listInfluencer?.is_verify === "2"
                                  ? "/influencer-rejected"
                                  : listInfluencer?.is_verify === "1"
                                  ? "/influencer-payment"
                                  : section.href,
                            })
                          : section.title === "Explore Crypto"
                          ? history.push(
                              social?.userDataWallet?.wallet_pin === null
                                ? "/create-pin"
                                : social?.userDataWallet?.mobile_status ===
                                    "0" ||
                                  social?.userDataWallet?.email_status === "0"
                                ? "/verify-kyc"
                                : "/crypto-login"
                            )
                          : section.title === "Notification"
                          ? history.push({
                              pathname:
                                type === "crypto"
                                  ? "/notification-lists"
                                  : "/notification-list",
                              state: {
                                notificationList: notificationList,
                                isLoading: isLoading,
                                page: 1,
                                noOfPages: noOfPages,
                              },
                            })
                          : history.push({
                              pathname: section.href,
                            });
                      }}
                    >
                      <Box className="circle">
                        <Icon color="#fff" fontSize="25px" />
                      </Box>

                      <Typography variant="h5" style={{ fontWeight: "200" }}>
                        &nbsp;&nbsp;{section.title}
                      </Typography>
                    </Button>
                  </Box>
                </>
              );
            })}
        </Box>
        <Box className={classes.termsPrivay}>
          <Typography variant="body2">
            <Link to="/about-us">About Us</Link> &nbsp;&nbsp;{" "}
            <Link to="/privacy-policy">Privacy Policy</Link> &nbsp;&nbsp;{" "}
            <Link to="/terms-conditions">Terms & Conditions</Link>&nbsp;&nbsp;
            {/* <Link to="/contact-us">Contact Us</Link> */}
            <span style={{ color: "#918e8e87" }}> Tarality .2023 </span>
          </Typography>
        </Box>
      </PerfectScrollbar>
      <SuccessFullModal
        successfulOpen={successfulOpen}
        setSuccessfulOpen={() => setSuccessfulOpen(false)}
        type="Influencer Added Successfully!!"
        key="bankacc"
      />
    </Box>
  );

  return (
    <>
      <Avatar
        src={`${IMAGE_PATH}` + userData?.profile_url}
        className={classes.avatar}
        onClick={() => {
          setRightBar(!rightBar);
        }}
      />
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Dialog>
    </>
  );
};

UseProfileNavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default UseProfileNavBar;
