// import { Contract } from "@ethersproject/contracts";
import * as XLSX from "xlsx";
import Web3 from "web3";
import ApiConfig from "src/Apiconfigs/config";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import { chatApiMainUrl } from "src/Apiconfigs/config";

const CryptoJS = require("crypto-js");
export const aesKey =
  "U2FsdGVkX18s6c8VorV8aKWr6bq8B2V5kFAQxFJvnKb1nByRKfmHmTZQ3CAfmWl";
export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}
export function sortAddress2(add) {
  const sortAdd = `${add?.slice(0, 15)}...${add?.slice(add.length - 2)}`;
  return sortAdd;
}
export function sortAddress3(add) {
  const sortAdd = `${add?.slice(0, 10)}...${add?.slice(add.length - 2)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

// export function getContract(address, ABI, library, account) {
//   return new Contract(address, ABI, getProviderOrSigner(library, account));
// }
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};
export const downloadExcel = (dataToPrint, sheetName) => {
  const workSheet = XLSX.utils.json_to_sheet(dataToPrint);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workBook, `${sheetName}.xlsx`);
};

export const validateAccountAddress = async (account) => {
  const RPC_URL = "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  try {
    const accountCheck = web3.utils.toChecksumAddress(account);
    if (accountCheck !== "") {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};
export const resizeBase64Img = async (base64, newWidth, newHeight) => {
  return new Promise((resolve, reject) => {
    // const imageThumbnail = require("image-thumbnail");
    // const thumbnail = imageThumbnail(base64);
    // console.log("--- imageQuality --", thumbnail);
    var canvas = document.createElement("canvas");
    canvas.style.width = newWidth.toString() + "px";
    canvas.style.height = newHeight.toString() + "px";
    let context = canvas.getContext("2d");
    let img = document.createElement("img");
    img.src = base64;
    img.onload = function () {
      context.scale(newWidth / img.width, newHeight / img.height);
      context.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
  });
};

export const handleUploadFiles = async (filedata) => {
  var data = new FormData();
  data.append("image", filedata);
  try {
    const response = await axios({
      method: "POST",
      url: ApiConfig.uploadImage,
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: data,
    });
    if (response.data.data) {
      const splitImg = response.data.data?.Key; //main network
      return splitImg;
    } else {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err.response);
  }
};
export const handleUploadFilesImage = async (filedata) => {
  var data = new FormData();
  data.append("image", filedata);
  try {
    const response = await axios({
      method: "POST",
      url: ApiConfig.uploadFile,
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: data,
    });
    if (response.data.data) {
      return response.data.data;
    } else {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err.response);
  }
};
export const mediaCompressorHandler = async (filedata) => {
  const mediatype = filedata?.type;
  var data = new FormData();
  data.append("media", filedata);
  if (
    mediatype === "image/jpeg" ||
    mediatype === "image/png" ||
    mediatype === "image/jpg" ||
    mediatype === "image/webp"
  ) {
    data.append("quality", 50);
    data.append("mediaType", "image");
  } else if (mediatype === "image/gif") {
    data.append("gifResize", 160);
    data.append("mediaType", "gif");
  } else {
    data.append("mediaType", "video");
  }
  try {
    const response = await axios({
      method: "POST",
      url: ApiConfig.compressMedia,
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: data,
    });
    if (response.data.status) {
      const splitImg = response?.data?.link?.split(".com/")[1]; //main network;
      return splitImg;
    } else {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err.response);
  }
};

export const uploadImageHandler = async (filedata) => {
  var data = new FormData();
  data.append("image", filedata);
  try {
    const response = await axios({
      method: "POST",
      url: ApiConfig.uploadImage,
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: data,
    });
    if (response.data.status === true) {
      return response.data.data;
    } else {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err.response);
  }
};

export const coinFamily = (coinFamily) => {
  return coinFamily == 1
    ? "(ERC20)"
    : coinFamily == 3
    ? "(BEP20)"
    : coinFamily == 4
    ? "(TRC20)"
    : "";
};

export const resizeBase64Img2 = (base64, newWidth, newHeight) => {
  // return (
  var canvas = base64;
  var ctx = canvas.getContext("2d");
  // var cw=canvas.width;
  // var ch=canvas.height;
  var maxW = 324;
  var maxH = 324;

  // var input = document.getElementById('input');
  // var output = document.getElementById('file_output');
  // input.addEventListener('change', handleFiles);

  // function handleFiles(e) {
  var img = new Image();
  img.onload = function () {
    var iw = img.width;
    var ih = img.height;
    var scale = Math.min(maxW / iw, maxH / ih);
    var iwScaled = iw * scale;
    var ihScaled = ih * scale;
    canvas.width = iwScaled;
    canvas.height = ihScaled;
    ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
    let output = canvas.toDataURL("image/jpeg", 0.5);
  };
  img.src = base64;
  // }
  // )
};
export function isValidFacebookURL(value) {
  const re =
    /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/;
  return re.test(value);
}
export function isValidInstaURL(value) {
  const re =
    /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)/;
  return re.test(value);
}
export function isValidURL(value) {
  const re =
    /((http|https):\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(value);
}
export function isValidTiktokURL(value) {
  const re =
    /(?:http(?:s)?:\/\/)?(?:(?:www)\.(?:tiktok\.com)(?:\/)(?!foryou)(@[a-zA-z0-9]+)(?:\/)(?:video)(?:\/)([\d]+)|(?:m)\.(?:tiktok\.com)(?:\/)(?!foryou)(?:v)(?:\/)?(?=([\d]+)\.html))/gm;
  return re.test(value);
}
export function isValidLinkedInURL(value) {
  const re = /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/;
  return re.test(value);
}
export function isValidYoutubeUrl(value) {
  const re =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
  return re.test(value);
}
export const encryptData = (params) => {
  let data = params;
  let ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    aesKey
  ).toString();
  return ciphertext;
};
export const getFixedValue = (calculateFee) => {
  var calculateFeeString = calculateFee.toString();
  let arrOfFee1 = calculateFeeString.split(".");
  var decimals = 6;
  if (arrOfFee1.length > 1) {
    var afterDecimals = arrOfFee1[1].substring(0, decimals);
    calculateFee = `${arrOfFee1[0]}.${afterDecimals}`;
  }

  return calculateFee;
};
export const exponentialToDecimal = (exponential) => {
  let decimal = exponential?.toString()?.toLowerCase();
  if (decimal?.includes("e+")) {
    const exponentialSplitted = decimal?.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes(".")
          ? exponentialSplitted[0].split(".")[1].length
          : 0);
      i++
    ) {
      postfix += "0";
    }
    const addCommas = (text) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)},${text.slice(
          textLength - j,
          textLength
        )}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal?.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal?.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
};

export const exponentialToDecimalWithoutComma = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x?.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x?.toString().substring(2);
    }
  } else {
    var e = parseInt(x?.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

export const setCryptoDecimals = (amt) => {
  amt = exponentialToDecimal(amt || 0);

  amt = amt?.replace(",", "");
  let arr = amt?.toString().split(".");

  if (arr.length > 1) {
    if (arr[1].length > 8) {
      return numberWithCommas(
        // exponentialToDecimal(parseFloat(amt).toFixed(8)).toString(),
        exponentialToDecimal(parseFloat(amt)).toString()
      ).toString();
    } else {
      return numberWithCommas(amt).toString();
    }
  } else {
    if (amt) {
      return numberWithCommas(amt).toString();
    }
    return "0";
  }
};
function intlFormat(num) {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
}
export function makeFriendly(num) {
  if (num >= 1000000) return intlFormat(num / 1000000) + "M";
  if (num >= 1000) return intlFormat(num / 1000) + "k";
  return intlFormat(num);
}

const numberWithCommas = (x) => {
  let str = toFixedFunction(x, 6);

  let arr = str.split(".");

  let numbers = arr[0];
  let decimalNum = "";
  if (arr.length > 1) {
    decimalNum = arr[1];
    return `${numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${decimalNum}`;
  } else {
    return numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
export const toFixedFunction = (amt, decimals) => {
  let str = amt?.toString();
  if (str?.includes(".")) {
    str = str.slice(0, str.indexOf(".") + (decimals + 1));
  }
  return str;
};

export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

export const getTxnDateTime = (date) => {
  return (
    moment(date).format("MMM DD, YYYY") +
    " " +
    "|" +
    " " +
    moment(date).format("hh:mm a")
  );
};
export const numberWithCommasPublic = (x) => {
  var parts = x?.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
export const coinData = [
  {
    value: "ETH",
    color: "#627EEA1A",
    image: "/images/Coin/ethIcon.png",
    borderColor: "rgba(99,126,234,1)",
    coin_family: 1,
  },
  {
    value: "BNB",
    color: "#F3BA2F1A",
    image: "/images/Coin/bnbIcon.png",
    borderColor: "rgba(244,186,48,1)",
    coin_family: 3,
  },
  {
    value: "TRON",
    color: "#C530271A",
    image: "/images/Coin/tronIcon.png",
    borderColor: "rgba(197,47,39,1)",
    coin_family: 4,
  },
  {
    value: "MATIC",
    color: "rgba(130,71,230,0.1)",
    image: "/images/Coin/polygon-matic.png",
    borderColor: "rgba(130,71,230,1)",
    coin_family: 5,
  },
];

export const convertINRDecimals = (value) => {
  let formatter = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  return formatter.format(value);
};
export const paramsVal = chatApiMainUrl
  ? {
      secure: true,
      reconnect: true,
      rejectUnauthorized: false,
      transports: ["websocket"],
    }
  : {
      secure: true,
      rejectUnauthorized: false,
      reconnect: true,
    };

export function formatTextDescrition(str) {
  const split = str.split(" ");
  let newArray = [];
  for (let i = 0; i < split.length; i++) {
    if (split[i] !== "") {
      const checkBracket = split[i].includes("#[");
      if (checkBracket) {
        const elemination1 = split[i].replace("[", "");
        const elemination2 = elemination1.replace("]", "");
        const elemination3 = elemination2.split("(");
        newArray.push(elemination3[0]);
      } else {
        newArray.push(split[i]);
      }
    }
  }

  return newArray.join(" ");
}

export function numberCompactFormat(value) {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(value);
}
